import React from 'react';

export const PortfolioAgreementDisclaimerText: React.FC = () => {
    return (
        <>
            <p><strong>IMPORTANT NOTICE AND DISCLAIMERS</strong></p>
            <p>
                <strong>The information contained herein (the "Information"), and your access to such Information, is subject to the following disclaimers and the KopenTech Terms of Use. Before being granted access to the Information, you must review and acknowledge this Important Notice and Disclaimers (the “Notice and Disclaimers”) by clicking "I ACCEPT AND AGREE" below.</strong>
            </p>
            <p>
                Your access to KopenTech’s Portfolio Monitoring Platform (the “Platform”) enables you, at your option, to input, upload or otherwise transmit (collectively, "Upload") data and information relating to your positions and potential positions (collectively, "Your Data") to the Platform. By Uploading Your Data, you grant to KopenTech and its affiliates a worldwide, non-exclusive, royalty-free, irrevocable and perpetual license to access, view, use, store, adapt, and create derivative works from Your Data in perpetuity, in all media now known or hereafter devised, for purposes of providing account support, including without limitation. You represent and warrant that: (a) you have all requisite right, power and authority to Upload Your Data to the Platform and grant to KopenTech the rights granted herein; (b) Your Data are accurate; and (c) Your Data and any related file you use to Upload Your Data are free from any computer code designed to damage, disrupt, disable, harm, or otherwise impede in any manner, the orderly operation of the Platform or any software, data files, firmware, hardware, computer system or network or that would permit other parties access to the Platform. KopenTech’s use of Your Data is governed by its Privacy Policy. To the extent the Information conflicts with the Privacy Policy, the Privacy Policy shall control.
            </p>
            <p>
                You hereby agree to indemnify, defend, and hold harmless KopenTech, its employees, agents, affiliates and licensors against any loss or damage arising from or relating to: (i) your use of the Platform, (ii) the breach or alleged breach by you of this Notice and Disclaimers, (iii) any claim that Your Data infringes any patent, copyright, trademark, trade secret or any other proprietary right of any third party; or (iv) violation by you of any law, rule or regulation. KopenTech may discontinue the availability of the Upload functionality at any time for any reason or no reason.
            </p>
            <p>
                The Information is being provided to you solely at your request and for your informational purposes only. While the Information is based upon information derived from sources believed to be reliable, KopenTech has not independently verified such Information and is not responsible for its contents. No representation is made as to the accuracy or completeness of the Information or any promise or representation as to the future performance of any securities, loans or other instruments referenced herein.  You are responsible for your own independent verification of the Information and should consult with your own advisors with respect to the Information and before deciding the uses to which the Information may be put. Neither the Information nor the assumptions reflected herein should be construed to be, or constitute, an offer to sell or buy or a solicitation of an offer to sell or buy any securities mentioned herein.
            </p>
            <p>
                The Information may contain "forward-looking" information, which may include, among other things, projections, forecasts or estimates of yields or return, scenario analyses and proposed or expected portfolio composition. The forward-looking information is based upon certain assumptions about future events or conditions and is intended only to illustrate hypothetical results under those assumptions. In addition, not all relevant events or conditions may have been considered in developing such assumptions. Actual events or conditions are unlikely to be consistent with and may differ materially from those assumed.  Assumptions used in these analyses subsequently may be determined to be unreasonable when used or may become unreasonable at a future time. Assumptions may be modified from time to time to reflect changed circumstances.
            </p>
            <p>
                KopenTech is not acting as your advisor or agent. Prior to making any investment decision or entering into any transaction, you should determine, without reliance upon KopenTech or any of its affiliates, the economic risks and merits, as well as the legal, tax and accounting characterizations and consequences of any investment or transaction, and independently determine that you are able to assume these risks. In this regard, by acceptance of these materials, you acknowledge that you have been advised that (a) KopenTech is not in the business of providing legal, tax or accounting advice, (b) you understand that there may be legal, tax or accounting risks associated with any investment or transaction, (c) you should receive legal, tax and accounting advice from advisors with appropriate expertise to assess relevant risks.
            </p>
            <p>
                [KopenTech acknowledges that Your Data provided to KopenTech in connection with your use of the Platform may be confidential, non-public, or proprietary (“Confidential Information”).<br />
                KopenTech and its affiliates shall maintain the Confidential Information in confidence and not disclose such information to third parties or use such information for any purpose other than for the provision of the Services. KopenTech shall advise its employees, officers, directors, or agents who may receive access to Confidential Information of their obligations to keep such information confidential and shall be responsible for any unauthorized disclosures by its respective employees, officers, directors, or agents.]
            </p>
            <p>
                KopenTech may remove content that appears in our sole discretion to infringe the intellectual property rights of others. KopenTech also has a policy of terminating the accounts of repeat infringers in appropriate circumstances. If you believe that content available on or through the Platform infringes one or more of your intellectual property rights, please immediately notify KopenTech’s copyright agent by mail, email or faxed notice (“Notification”) providing the information described below. A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification.
            </p>
            <p>
                Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that content located on or linked to by the Platform infringes your rights, you should consider first contacting an attorney.
            </p>
            <p>
                All Notifications should include the following:
                <ul>
                    <li>&bull; A physical or electronic signature of a person authorized to act on behalf of the owner of the intellectual property right that is allegedly infringed.</li>
                    <li>&bull; Identification of the copyrighted work claimed to have been infringed.</li>
                    <li>&bull; Identification of the material that you claim is infringing and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit KopenTech to locate the material, including the URL.</li>
                    <li>&bull; Your address, telephone number and email address.</li>
                    <li>&bull; A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
                    <li>&bull; A statement made under penalty of perjury that the information in the Notification is accurate, and that you are authorized to act on behalf of the owner of the intellectual property right that is allegedly infringed.</li>
                </ul>
            </p>
            <p>
                Notifications should be sent to KopenTech’s copyright agent as follows:
            </p>
            <address>
                KopenTech LLC<br/>
                10880 Wilshire Boulevard<br />
                11th Floor Los Angeles CA 90024<br />
                1.800.862.1684<br />
                info@kopentech.com
            </address>
            <p>
                [This Platform is not absolutely protected against unauthorized third parties. You acknowledge that any information provided through the internet may be potentially accessed by unauthorized third parties. Although KopenTech has implemented security measures designed to protect the privacy of users of this Site, no guarantee can be made that unauthorized third parties will not access the information contained on the Platform.]
            </p>
            <p>
                <strong>THIS PLATFORM AND ALL INFORMATION ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW. WITHOUT LIMITATION, KOPENTECH DOES NOT WARRANT THE ACCURACY, ADEQUACY, COMPLETENESS, RELIABILITY, TIMELINESS OR AVAILABILITY OF THIS PLATFORM OR ANY INFORMATION ON THIS PLATFORM, AND EXPRESSLY DISCLAIMS LIABILITY FOR ANY ERRORS OR OMISSIONS ON THIS PLATFORM. THERE IS NO WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, OR ANY OTHER WARRANTY OF ANY KIND, IMPLIED, EXPRESS OR STATUTORY. ANY INFORMATION IS SUBJECT TO CHANGE WITHOUT NOTICE. THE INFORMATION MAY CONTAIN INACCURACIES OR TYPOGRAPHICAL ERRORS. KOPENTECH ASSUMES NO RESPONSIBILITY FOR, AND MAKES NO WARRANTIES THAT, FUNCTIONS CONTAINED AT THIS PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. IN ADDITION, KOPENTECH MAKES NO REPRESENTATION ABOUT THE SUITABILITY OF THE PLATFORM OR INFORMATION FOR ANY PURPOSE. YOU (AND NOT KOPENTECH) ASSUME THE ENTIRE COST AND RISK OF USING THIS PLATFORM INCLUDING THE INFORMATION. PLEASE NOTE THAT APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</strong>
            </p>
            <p>
                <strong>IN NO EVENT WILL KOPENTECH BE LIABLE FOR ANY DAMAGES INCLUDING WITHOUT LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THIS PLATFORM OR THE USE THEREOF OR THE INABILITY BY ANY PARTY TO USE THIS PLATFORM, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE IN CONNECTION WITH THIS WEBSITE OR THE PLATFORM, EVEN IF KOPENTECH, OR REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH JURISDICTIONS LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</strong>
            </p>
            <p>
                <strong>"I ACCEPT AND AGREE"</strong>
            </p>
            <p>
                SK 30021 0001 9935790 v3
            </p>
        </>
    )
}
