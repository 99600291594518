import React from 'react';
import { Formik, FormikValues, Form } from 'formik';
import { Popup, PopupBody, PopupFooter } from '../controls';
import { constants, errorMessages } from '../../constants';
import { yup } from '../../validation/yup';
import { InputField } from '../forms';

interface Props {
    onClose: () => void;
    onNameChange: (name: string) => void;
    title: string;
    portfolioName: ''
}

export const PortfolioNameDialog: React.FC<Props> = ({ onClose, onNameChange, title, portfolioName }) => {
    const validationSchema = yup.object().shape({
        name: yup.string().trim().matches(constants.nonWhitespace).required(errorMessages.portfolioNameIsRequired).max(64),
    })

    const handleSubmit = (values: FormikValues) => {
        onNameChange(values.name.trim())
    }

    return (
        <Popup title={title} modalClass="modal-add-to-portfolio" onClose={onClose}>
            <Formik
                initialValues={{ name: portfolioName }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ errors }) => (
                    <Form noValidate={true}>
                        <PopupBody>
                            <div className="form-item portfolio-name-cnt">
                                <InputField
                                    className="form-control"
                                    label="Portfolio Name"
                                    placeholder="Enter name"
                                    markRequired={true}
                                    name="name"
                                    maxLength={64}
                                />
                            </div>
                        </PopupBody>
                        <PopupFooter>
                            <button type="button" className="btn btn-ghost" onClick={onClose}> Cancel</button>
                            <button type="submit" className="btn btn-main" disabled={!!errors.name}>Save</button>
                        </PopupFooter>
                    </Form>
                )}
            </Formik>
        </Popup>
    )
}

PortfolioNameDialog.defaultProps = {
    portfolioName: ''
}
