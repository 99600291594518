import { MinBidIncrement } from "./MinBidIncrement";
import { SettingsLine } from "./SettingsLine";

export const MinBidIncrementSettingsLine = () => (
    <SettingsLine
        title="Minimum bid improvement"
        tooltip="Minimal improvement over the previous bid accepted. Bidders will not be able to submit a level below the previous bid plus Minimum bid improvement."
    >
        <MinBidIncrement />
    </SettingsLine>
)