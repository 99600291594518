import { ButtonHTMLAttributes } from 'react';
import * as React from 'react';
import { PortfolioAgreementDisclaimerContext } from './PortfolioAgreementDisclaimerContext';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>{
    text?: string
}

export function CreatePortfolioButton({ className = '', text = '', disabled = false, children = null, onClick, ...rest }: Props) {
    const isPortfolioDisclaimerBlocked = React.useContext(PortfolioAgreementDisclaimerContext);

    return (
        <button
            className={className}
            disabled={disabled}
            onClick={isPortfolioDisclaimerBlocked ? undefined : onClick}
            {...rest}
        >
            {children} {text}
        </button>
    );
}
