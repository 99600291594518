import { Portfolio } from '../../../types/portfolio/Portfolio';
import { keys } from 'lodash';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { Checkbox } from '../../controls';
import { portfolioActions } from '../../../actions';
import { OnHoverTooltip } from '../../common';
import { useAllToAllAgreementBlocked } from '../../../effects/useAllToAllAgreementBlocked';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface SelectedPortfolioState {
    selectAll: boolean;
    securitiesSelectedState?: { [id: number]: boolean }
}

interface Props {
    portfolio: Portfolio;
    selectedPortfolioState: SelectedPortfolioState
}

export const PortfolioSelectAllCheckbox = (
    {
        portfolio,
        selectedPortfolioState = { selectAll: false, securitiesSelectedState: {} }
    }: Props) => {
    const dispatch = useAppDispatch();

    const isSeller = user.hasRoles(...roles.seller());
    const isSettlementAgent = user.current()?.isClearingBank;
    const isBrokerDealerTrader = user.hasRoles(roles.BrokerDealerTrader);
    const allToAllAgreementBlocked = useAllToAllAgreementBlocked();

    const counter = portfolio.securities.length;
    const counterText = counter === 1 ? 'security' : 'securities';

    const securitiesSelectedState = selectedPortfolioState.securitiesSelectedState || {};
    const securityIds = keys(securitiesSelectedState);
    const isPartiallySelected = (
        securityIds.some(key => securitiesSelectedState[+key]) &&
        !(securityIds.length === portfolio.securities.length && securityIds.every(key => securitiesSelectedState[+key]))
    );

    const сounter = (
        <div className="text-ellipsis">
            <span className="text-bold">{portfolio.name}</span> {`(${counter} ${counterText})`}
        </div>
    );

    if (isSeller || (isBrokerDealerTrader && !allToAllAgreementBlocked && !isSettlementAgent)) {
        return (
            <div className="portfolio-name">
                <OnHoverTooltip overlay={сounter}>
                    <Checkbox
                        partially={portfolio.securities && isPartiallySelected}
                        checked={selectedPortfolioState.selectAll}
                        onChange={e => dispatch(portfolioActions.portfolioSelectionChange(portfolio.id, e.target.checked))}
                        disabled={!portfolio.securities || !portfolio.securities.length}
                        label={сounter}
                    />
                </OnHoverTooltip>
            </div>
        )
    }
    return (
        <div className="portfolio-name">
            <OnHoverTooltip overlay={сounter}>
                {сounter}
            </OnHoverTooltip>
        </div>
    )
};
