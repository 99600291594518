import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { onBoardingTooltipConst, roles } from '../../constants';
import {
    onBoardingTooltipNav,
    onBoardingTooltipGotIt,
    onBoardingTooltipClose,
    onBoardingTooltipSetCountRequest
} from '../../actions';
import IconSVG from '../../styles/svg-icons';
import { user } from '../../user';
import { useCacheOnBoardingState } from '../../effects';

const mountedTooltips = Object.values(onBoardingTooltipConst.pages).reduce((object, page) => {
    object[page] = {};
    return object
}, {});

const setPageCountRequest = {};

export function OnBoardingTooltip({ page, step, className = '', hidden = false }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const stateBoardingTooltip = useSelector((s) => s.onBoardingTooltip);
    const currentUser = user.current() || {};

    useCacheOnBoardingState(step, stateBoardingTooltip);

    useEffect(() => {
        mountedTooltips[page][step] = step;
        const counts = user.getOnBoardingTooltipCounters();
        if (
            getVisibleStatus() &&
            typeof counts[page] === 'number' &&
            !setPageCountRequest[page] &&
            counts[page] + 1 <= onBoardingTooltipConst.maxCountLimit
        ) {
            dispatch(onBoardingTooltipSetCountRequest([{ page, count: Number(counts[page]) + 1 }]));
            setPageCountRequest[page] = true;
        }
        return () => {
            if (mountedTooltips[page] && mountedTooltips[page][step]) {
                delete mountedTooltips[page][step];
            }
            if (setPageCountRequest[page]) {
                delete setPageCountRequest[page];
            }
        } // eslint-disable-next-line
    }, [currentUser.id, hidden]);

    const getCurrentStep = () => {
        if (!stateBoardingTooltip.pages[page]) return 1;
        return stateBoardingTooltip.pages[page].current;
    };

    const getCountMountedPages = () => Object.keys(mountedTooltips[page]).length;

    const getTemplateIndex = () => {
        const tooltips = Object.keys(mountedTooltips[page]);
        const currentStateStep = getCurrentStep();
        const tooltipIndex = tooltips[currentStateStep - 1];
        return Number(tooltipIndex) || 0
    };

    const getVisibleStatus = () => {
        if (hidden) return false;
        if (!user.hasRoles(roles.Viewer) || !step) return false;
        if (history.location.pathname !== onBoardingTooltipConst.urls[page]) return false;
        if (!onBoardingTooltipConst.items[page] || !onBoardingTooltipConst.items[page][step - 1] || !stateBoardingTooltip.pages[page]) return false;
        if (!currentUser || !currentUser.tooltipCounts || typeof currentUser.tooltipCounts !== 'object') return false;
        return !(stateBoardingTooltip.pages[page].closed || stateBoardingTooltip.pages[page].gotIt) && getTemplateIndex() === step;
    };

    const onNext = e => {
        e.stopPropagation();
        dispatch(onBoardingTooltipNav(page, true))
    };

    const onPrevious = e => {
        e.stopPropagation();
        dispatch(onBoardingTooltipNav(page, false))
    };

    const onGotIt = e => {
        e.stopPropagation();
        dispatch(onBoardingTooltipGotIt(page))
    };

    const getTitle = () => {
        if (onBoardingTooltipConst.items[page][getTemplateIndex() - 1]) {
            return onBoardingTooltipConst.items[page][getTemplateIndex() - 1].message;
        }
        return ''
    };

    const onClose = e => {
        e.stopPropagation();
        dispatch(onBoardingTooltipClose(page))
    };

    const getCurrentNumber = () => {
        return <span>{getCurrentStep()} of {getCountMountedPages()}</span>
    };

    const getNavButtons = () => {
        if (step < getCountMountedPages()) {
            return (
                <>
                    {step > 1 && <button className="btn-link" type="button" onClick={onPrevious}>Previous</button>}
                    <button className="btn btn-main btn-sm" type="button" onClick={onNext}>Next</button>
                </>
            )
        }
        return (
            <>
                <button className="btn-link" type="button" onClick={onPrevious}>Previous</button>
                <button className="btn btn-main btn-sm" type="button" onClick={onGotIt}>Got it</button>
            </>
        )
    };

    if (!getVisibleStatus()) {
        return null;
    }

    return (
        <div className={classNames("popover popover-onboarding", className)} onClick={(e) => e.stopPropagation()}>
            <div className="popover-header">
                <button type="button" onClick={onClose}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
            <div className="popover-body">
                <div className="popover-content">
                    <p>{getTitle()}</p>
                    <div className="flex-row">
                        {getCurrentNumber()}
                        <div className="flex-item-right">{getNavButtons()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
