import * as React from 'react';
import cn from 'classnames';
import sanitizeHtml from 'sanitize-html';

interface Props {
    className?: string
    text?: string;
}

export function NotificationBody({ text, className = 'notification-message' }: Props) {
    const messageLines = (text || '').trim().split('\n');
    const outputMessage = [];

    const sanitizeText = (text: string) => sanitizeHtml(text, {
        allowedTags: ['a', 'br', 'div', 'span', 'b', 'ul', 'li'],
        allowedAttributes: {
            'a': ['href', 'target'],
            'div': ['class'],
            'span': ['class'],
            'ul': ['class']
        }
    });

    const hasHtml = (text: string) => /<[a-z][\s\S]*>/i.test(text);

    for (let i = 0; i < messageLines.length; i++) {
        const rawText = messageLines[i];
        const text = hasHtml(rawText) ? sanitizeText(rawText) : rawText;
        outputMessage.push(text);
        if (i !== messageLines.length - 1) {
            outputMessage.push('</br>');
        }
    }

    return <div className={cn(className)} dangerouslySetInnerHTML={{ __html: outputMessage.join('') }} />;
}
