import moment from 'moment';
import { collateralTypes } from '../../../constants/collateral-types';
import { dateRangeFilterOptions } from '../../../constants/date-range.filter';
import { CollateralType } from '../../../types/amr-pipeline/enums/CollateralType';
import { Currencies } from '../../../types/enums/Currency';
import { Ratings } from '../../../types/enums/Rating';
import { FilterType } from '../../../types/filters/FilterGroup';

export const portfolioInitialFilters = {
    visibleFilters: ['collateralType', 'currency', 'managers', 'ratings'],
    hiddenFilters: ['dealName', 'maturity', 'vintage', 'trustees', 'amr', 'closing', 'nonCallEnd', 'reinvestmentEnd', 'esg', 'euCompliance', 'staticDeal', 'outOfNC', 'outOfRI'],
    defaultFilter: {
        collateralType: {
            key: 'collateralType',
            type: FilterType.Select,
            visible: true,
            text: 'Collat',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [
                {
                    value: CollateralType.broadlySyndicated,
                    text: collateralTypes.BroadlySyndicated,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
                {
                    value: CollateralType.middleMarket,
                    text: collateralTypes.MiddleMarket,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
            ],
        },
        currency: {
            key: 'currency',
            type: FilterType.Select,
            visible: true,
            text: 'Ccy',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: Currencies.map(v => ({
                value: v,
                text: v,
                selected: false,
                disabled: false,
                visible: true,
            })),
        },
        managers: {
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Manager',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'managers',
        },
        maturity: {
            key: 'maturity',
            type: FilterType.Date,
            visible: false,
            text: 'Maturity',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.maturity(),
            disabledDays: { before: moment().add(1, 'day').toDate() },
        },
        vintage: {
            key: 'vintage',
            type: FilterType.YearsDate,
            visible: false,
            text: 'Vintage',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.vintage(),
        },
        closing: {
            key: 'closing',
            type: FilterType.Date,
            visible: false,
            text: 'Closing',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.closing(),
            disabledDays: { after: moment().subtract(1, 'day').toDate() },
        },
        trustees: {
            withSearch: true,
            type: FilterType.Select,
            visible: false,
            text: 'Trustee',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'trustees',
        },
        amr: {
            key: 'amr',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Deal with AMR',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        ratings: {
            key: 'ratings',
            type: FilterType.Select,
            visible: true,
            text: 'Rtg',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: Ratings.map(r => ({
                value: r,
                text: r,
                selected: false,
                visible: true,
                disabled: false,
            })),
        },
        dealName: {
            withSearch: true,
            type: FilterType.Select,
            visible: false,
            text: 'Deal Name',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'dealName',
        },
        nonCallEnd: {
            key: 'nonCallEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'NC End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.nonCallEnd(),
        },
        reinvestmentEnd: {
            key: 'reinvestmentEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'RI End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.reinvestmentEnd(),
        },
        esg: {
            key: 'esg',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'ESG',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        euCompliance: {
            key: 'euCompliance',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'EU Compliance',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        staticDeal: {
            key: 'staticDeal',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Static Deal',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        outOfNC: {
            key: 'outOfNC',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of NC',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        outOfRI: {
            key: 'outOfRI',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of Rl',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
    }
}
