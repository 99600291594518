import React from 'react';
import MaskedInput from "react-text-mask";
import { constants } from "../../constants";
import { useAppSelector } from "../../effects/useAppSelector";
import { dateTimeUtils } from "../../utils";
import { FormError, RadioButton } from "../controls";
import classNames from "classnames";
import { createNumberMask } from "text-mask-addons";
import { processActions } from "../../actions";
import { useAppDispatch } from '../../effects/useAppDispatch';

export function AutoFeedbackDelay() {
    const dispatch = useAppDispatch();

    const autoFeedbackDelayTimeSpan = useAppSelector(s => s.process.templates.current?.autoFeedbackDelay);
    const autoFeedbackDelay = autoFeedbackDelayTimeSpan
        ? dateTimeUtils.parseTimeSpan(autoFeedbackDelayTimeSpan).totalMinutes
        : undefined;
    const error = useAppSelector(s => s.process.errors.templates.autoFeedbackDelay);

    return (
        <>
            <div className="radio-group">
                <RadioButton
                    label="Off"
                    name="auto-feedback-delay"
                    checked={autoFeedbackDelayTimeSpan == null}
                    onChange={() => dispatch(processActions.autoFeedbackDelayDisabled())}
                />
                <RadioButton
                    label="On"
                    name="-feedback-delay"
                    checked={autoFeedbackDelayTimeSpan != null}
                    onChange={() => dispatch(processActions.autoFeedbackDelayEnabled())}
                />
                <label className="form-label"><span className="text-red">*</span></label>
            </div>
            <div className="form-control-wrapper">
                {
                    autoFeedbackDelayTimeSpan == null
                        ? <input disabled className="form-control range" placeholder={String(constants.autoFeedbackDelayDefaultMinutes)} />
                        : <MaskedInput
                            value={String(autoFeedbackDelay || '')}
                            className={classNames(
                                'form-control range',
                                { 'is-invalid': autoFeedbackDelayTimeSpan != null && error }
                            )}
                            maxLength={2}
                            mask={createNumberMask({
                                prefix: "",
                                suffix: "",
                                allowDecimal: false,
                                includeThousandsSeparator: false,
                                integerLimit: 2,
                            })}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(processActions.autoFeedbackDelayChange(e.target.value))}
                        />
                }
                <span className="info-label">
                    Minutes
                </span >
                <FormError message={autoFeedbackDelayTimeSpan != null && error} />
            </div>
        </>
    );
}
