import cn from 'classnames';
import { NewBwicProcess, BwicProcessType } from '../../../types/models/Process';
import { AnimatedValueUpdate } from '../../controls/AnimatedValueUpdate';
import { useAppSelector } from '../../../effects/useAppSelector';
import { dateTimeUtils, formatUtils } from '../../../utils';

function ProcessLiveBidding({ descriptionData, isSelected }: { descriptionData: NewBwicProcess, isSelected?: boolean }) {
    const isLiveBiddingSelected = useAppSelector(s => s.process.templates.current?.type === BwicProcessType.Live);
    const errors = useAppSelector(s => s.process.errors.templates);


    return (
        <div className="template-list-item-text">
            <h5>Stage 1</h5>
            <div className="option-row">
                Participants submit bids for
                <AnimatedValueUpdate
                    value={`${descriptionData.liveBidding?.stage1Deadline ? `${descriptionData.liveBidding?.stage1Deadline} minutes ` : "?  minutes"} `}
                    className={errors.stage1Deadline && "animated-value-update-error"}
                    msAnimationTime={700}
                />
            </div>
            <div className="option-row">
                Reminder to bid
                <AnimatedValueUpdate
                    value={`${dateTimeUtils.parseTimeSpan(descriptionData?.cutOffReminder).totalMinutes
                        ? `${dateTimeUtils.parseTimeSpan(descriptionData?.cutOffReminder).totalMinutes} minutes`
                        : "? minutes"}`}
                    msAnimationTime={700}
                    backgroundClassName=""
                    className={cn({ "animated-value-update-error": isSelected && errors.cutOffReminder })}
                />
            </div>
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">no</span>.</div>
            <h5>Stage 2</h5>
            <div className="option-row first-animation-option">
                <AnimatedValueUpdate
                    value={`Top ${descriptionData.liveBidding?.openBiddingImproverCount}`}
                    msAnimationTime={700}
                />
                bidders see <span className="text-medium">Best</span> price and can<br/>improve the minimum by
                <AnimatedValueUpdate
                    value={`${Number(descriptionData.liveBidding?.minBidIncrement) === 0
                        ? "? bps"
                        : `${formatUtils.formatBid(descriptionData.liveBidding?.minBidIncrement)} bps`}`}
                    msAnimationTime={700}
                    className={isLiveBiddingSelected ? errors.minBidIncrement && "animated-value-update-error" : ""}
                />
                over <span className="text-medium">Best</span>.
            </div>
            <div className="option-row">
                Improvement round
                <AnimatedValueUpdate
                    value={`${descriptionData.liveBidding?.improvementRound ? `${descriptionData.liveBidding?.improvementRound} minutes ` : "?  minutes"}`}
                    msAnimationTime={700}
                    className={errors.improvementRound && "animated-value-update-error"}
                />
            </div>
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">auto</span>.</div>
        </div>
    )

}

function ProcessJumpBall({ data, isSelected }: { data: NewBwicProcess, isSelected?: boolean }) {
    const errors = useAppSelector(s => s.process.errors.templates);
    return (
        <div className="template-list-item-text">
            <div className="option-row">
                Autofeedback delay
                <AnimatedValueUpdate
                    value={`${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes
                        ? `${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes} minutes`
                        : (data.autoFeedbackDelay != null
                                ? 'On'
                                : 'Off'
                        )}`}
                    msAnimationTime={700}
                    backgroundClassName=""
                    className={cn({ "animated-value-update-error": isSelected && errors.autoFeedbackDelay })}
                />
            </div>
            <h5>Stage 1</h5>
            <div className="option-row">
                {data.jumpBall?.automaticStage1Deadline == null
                    ? (
                        <>
                            Participants submit bids till the end of Stage 1.
                        </>
                    )
                    : (
                        <>
                            Participants submit bids for
                            <AnimatedValueUpdate
                                value={`${dateTimeUtils.parseTimeSpan(data.jumpBall?.automaticStage1Deadline).totalMinutes
                                    ? `${dateTimeUtils.parseTimeSpan(data.jumpBall?.automaticStage1Deadline).totalMinutes} minutes`
                                    : "? minutes"}`}
                                msAnimationTime={700}
                                backgroundClassName=""
                                className={errors.jumpBallStage1AutomaticMinutes && "animated-value-update-error"}
                            />
                        </>
                    )}
            </div>
            {data?.jumpBall?.automaticStage1Deadline && (
                <div className="option-row">
                    Reminder to bid
                    <AnimatedValueUpdate
                        value={`${dateTimeUtils.parseTimeSpan(data.cutOffReminder).totalMinutes
                            ? `${dateTimeUtils.parseTimeSpan(data.cutOffReminder).totalMinutes} minutes`
                            : "? minutes"}`}
                        msAnimationTime={700}
                        backgroundClassName=""
                        className={cn({ "animated-value-update-error": isSelected && errors.cutOffReminder })}
                    />
                </div>
            )}
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited.</span></div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">auto/manual</span>.</div>
            <h5>Stage 2</h5>
            <div className="option-row first-animation-option">
                <AnimatedValueUpdate
                    value={`Top ${data.jumpBall?.improverCount}`}
                    msAnimationTime={700}
                />
                bidders proceed to Stage 2.
            </div>
            <div className="option-row">
                Stage 2 starts
                <AnimatedValueUpdate
                    value={`${data.jumpBall?.automaticStage1Deadline == null ? "manually" : "automatically"}`}
                    msAnimationTime={700}
                />
                {data.jumpBall?.automaticStage1Deadline == null && "by the Seller."}
            </div>
            <div className="option-row">
            {data.jumpBall?.improvementRound == null
                ? (
                    <>
                        Bidding continues till the end of BWIC.
                    </>
                )
                : (
                    <>
                        Final bids are due
                        <AnimatedValueUpdate
                            value={`${dateTimeUtils.parseTimeSpan(data.jumpBall?.improvementRound).totalMinutes
                                ? `${dateTimeUtils.parseTimeSpan(data.jumpBall?.improvementRound).totalMinutes} minutes`
                                : "? minutes"}`}
                            msAnimationTime={700}
                            className={errors.jumpBallStage2DurationMinutes && "animated-value-update-error"}
                        />
                        from the Stage 2 start.
                    </>
                    )}
            </div>
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">once</span>.</div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">manual</span>.</div>
        </div>
    )
}

function ProcessTopX({ data, isSelected }: { data: NewBwicProcess, isSelected?: boolean }) {

    const errors = useAppSelector(s => s.process.errors.templates);

    return (
        <div className="template-list-item-text">
            <div className="option-row">
                Autofeedback delay
                <AnimatedValueUpdate
                    value={`${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes
                        ? `${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes} minutes`
                        : (data.autoFeedbackDelay != null
                                ? 'On'
                                : 'Off'
                        )}`}
                    msAnimationTime={700}
                    backgroundClassName=""
                    className={cn({ "animated-value-update-error": isSelected && errors.autoFeedbackDelay })}
                />
            </div>
            <h5>Stage 1</h5>
            <div className="option-row">
                {data.topX?.automaticStage1Deadline == null
                    ? (
                        <>
                            Participants submit bids till the end of Stage 1.
                        </>
                    )
                    : (
                        <>
                            Participants submit bids for
                            <AnimatedValueUpdate
                                value={`${dateTimeUtils.parseTimeSpan(data.topX?.automaticStage1Deadline).totalMinutes
                                    ? `${dateTimeUtils.parseTimeSpan(data.topX?.automaticStage1Deadline).totalMinutes} minutes`
                                    : "? minutes"}`}
                                msAnimationTime={700}
                                backgroundClassName=""
                                className={errors.topXStage1AutomaticMinutes && "animated-value-update-error"}
                            />
                        </>
                    )}
            </div>
            {data?.topX?.automaticStage1Deadline && (
                <div className="option-row">
                    Reminder to bid
                    <AnimatedValueUpdate
                        value={`${dateTimeUtils.parseTimeSpan(data.cutOffReminder).totalMinutes
                            ? `${dateTimeUtils.parseTimeSpan(data.cutOffReminder).totalMinutes} minutes`
                            : "? minutes"}`}
                        msAnimationTime={700}
                        backgroundClassName=""
                        className={cn({ "animated-value-update-error": isSelected && errors.cutOffReminder })}
                    />
                </div>
            )}
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">auto/manual</span>.</div>
            <h5>Stage 2</h5>
            <div className="option-row first-animation-option">
                <AnimatedValueUpdate
                    value={`Top ${data.topX?.improverCount}`}
                    msAnimationTime={700}
                />
                bidders proceed to Stage 2.
            </div>
            <div className="option-row">
                Stage 2 starts
                <AnimatedValueUpdate
                    value={`${data.topX?.automaticStage1Deadline == null ? "manually" : "automatically"}`}
                    msAnimationTime={700}
                />
                {data.topX?.automaticStage1Deadline == null && "by the Seller."}
            </div>
            <div className="option-row">
                {data.topX?.improvementRound == null
                    ? (
                        <>
                            Bidding continues till the end of BWIC.
                        </>
                    )
                    : (
                        <>
                            Final bids are due
                            <AnimatedValueUpdate
                                value={`${dateTimeUtils.parseTimeSpan(data.topX?.improvementRound).totalMinutes
                                    ? `${dateTimeUtils.parseTimeSpan(data.topX?.improvementRound).totalMinutes} minutes`
                                    : "? minutes"}`}
                                msAnimationTime={700}
                                className={errors.topXStage2DurationMinutes && "animated-value-update-error"}
                            />
                            from the Stage 2 start.
                        </>
                    )}
            </div>
            <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
            <div className="option-row">Bid feedback &#8212; <span className="text-medium">auto/manual</span>.</div>
        </div>
    )
}

function ProcessStandart({ data, isSelected }: { data: NewBwicProcess, isSelected?: boolean }) {
    const errors = useAppSelector(s => s.process.errors.templates);

    return <div className="template-list-item-text">
        <div className="option-row"><span className="text-medium">One stage only</span>.</div>
        <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
        <div className="option-row">Bid feedback &#8212; <span className="text-medium">auto/manual</span>.</div>
        <div className="option-row">
            Autofeedback delay
            <AnimatedValueUpdate
                value={`${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes
                    ? `${dateTimeUtils.parseTimeSpan(data.autoFeedbackDelay).totalMinutes} minutes`
                    : (data.autoFeedbackDelay != null
                        ? 'On'
                        : 'Off'
                    )}`}
                msAnimationTime={700}
                backgroundClassName=""
                className={cn({ "animated-value-update-error": isSelected && errors.autoFeedbackDelay })}
            />
        </div>
    </div>
}

function ProcessBestFootForward({ descriptionData, isSelected }: { descriptionData: NewBwicProcess, isSelected?: boolean }) {

    const errors = useAppSelector(s => s.process.errors.templates);

    return <div className="template-list-item-text">
        <div className="option-row"><span className="text-medium">One stage only</span>.</div>
        <div className="option-row">Bid improvement &#8212; <span className="text-medium">unlimited</span>.</div>
        <div className="option-row">Bid feedback &#8212; <span className="text-medium">manual</span>.</div>
        <div className="option-row">
            Bids submission duration
            <AnimatedValueUpdate
                value={`${dateTimeUtils.parseTimeSpan(descriptionData.bff?.stage1Deadline).totalMinutes
                    ? `${dateTimeUtils.parseTimeSpan(descriptionData.bff?.stage1Deadline).totalMinutes} minutes`
                    : "? minutes"}`}
                msAnimationTime={700}
                backgroundClassName=""
                className={errors.bestFootForwardDurationMinutes && "animated-value-update-error"}
            />
        </div>
        <div className="option-row">
            Reminder to bid
            <AnimatedValueUpdate
                value={`${dateTimeUtils.parseTimeSpan(descriptionData?.cutOffReminder).totalMinutes
                    ? `${dateTimeUtils.parseTimeSpan(descriptionData?.cutOffReminder).totalMinutes} minutes`
                    : "? minutes"}`}
                msAnimationTime={700}
                backgroundClassName=""
                className={cn({ "animated-value-update-error": isSelected && errors.cutOffReminder })}
            />
        </div>
    </div>
}

export function ProcessDescription({ descriptionData, isSelected }: { descriptionData: NewBwicProcess, isSelected?: boolean }) {

    switch (descriptionData.type) {
        case BwicProcessType.Live: return <ProcessLiveBidding isSelected={isSelected} descriptionData={descriptionData} />
        case BwicProcessType.JumpBall: return <ProcessJumpBall isSelected={isSelected} data={descriptionData} />
        case BwicProcessType.TopX: return <ProcessTopX isSelected={isSelected} data={descriptionData} />
        case BwicProcessType.BestFootForward: return <ProcessBestFootForward isSelected={isSelected} descriptionData={descriptionData} />
        default: return <ProcessStandart isSelected={isSelected} data={descriptionData} />
    }

}
