import * as React from 'react';
import { BwicDate } from './BwicDate';
import { DaysToSettle } from './DaysToSettle';
import { TimeZone } from './TimeZone';
import { Time } from './Time';

export function BwicDates() {
    return (
        <div className="filters-area flex-row align-items-flex-end">
            <div className="filter-section">
                <BwicDate />
            </div>
            <div className="filter-section">
                <Time />
            </div>
            <div className="filter-section time-zone">
                <TimeZone />
            </div>
            <div className="filter-section">
                <DaysToSettle />
            </div>
        </div>
    );
}
