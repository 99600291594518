import { StatusMessageSection } from '../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../types/state/NotificationState';

interface Props {
    stage: number;
}

export function BwicManualStageEndingInfo({ stage }: Props) {
    return (
        <StatusMessageSection type={StatusMessageSectionType.Info}>
            {`The Seller manually determines the end of Stage ${stage}`}
        </StatusMessageSection>
    );
}
