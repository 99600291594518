import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { portfolioActions as actions } from '../../actions';
import { routes } from '../../constants';
import IconSVG from '../../styles/svg-icons';
import { Portfolio } from '../../types/portfolio/Portfolio';
import { ContextMenu } from '../controls';
import { ContextMenuItem } from '../controls/ContextMenuItem';

interface Props {
    portfolio: Portfolio;
}

export function PortfolioOptions({ portfolio }: Props) {
    const dispatch = useDispatch();

    const handleOnDeletePortfolio = (portfolio: Portfolio) => (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(actions.deletePortfolioConfirmation(true, portfolio));
    };

    return (
        <ContextMenu
            customTargetComponent={<span className="btn-link"><IconSVG name="more" width={16} height={16} /></span>}
        >
            <ContextMenuItem>
                <Link className="btn-link" to={routes.editPortfolioUrl(portfolio.referenceName)}>
                    Edit Portfolio
                </Link>
            </ContextMenuItem>
            <ContextMenuItem classNames="btn-link btn-danger" onClick={handleOnDeletePortfolio(portfolio)}>
                Delete Portfolio
            </ContextMenuItem>
        </ContextMenu >
    )

}
