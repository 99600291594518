import * as React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { FormFieldLabel } from '../forms/FormFieldLabel';
import { constants } from '../../constants/constants';
import { dateTimeUtils } from '../../utils';
import IconSVG from '../../styles/svg-icons';

export function BwicDateTimeSection() {
    const timeZone = useSelector((s: AppState) => s.process.timeZones.find(tz => tz.id === s.process.timeZone));
    const date = useSelector((s: AppState) => s.process.date);
    const bidsDue = useSelector((s: AppState) => s.process.bidsDue);
    const goodUntil = useSelector((s: AppState) => s.process.goodUntil);
    const daysToSettle = useSelector((s: AppState) => s.process.daysToSettle);

    const renderField = (title: string | React.ReactNode, value: string | number) => {
        return (
            <li><FormFieldLabel text={title}/> {value}</li>
        );
    }

    return (
        <div className="bwic-date-time">
            <ul className="bwic-date-time-list">
                {renderField(<IconSVG name="calendar" width="16" height="16" />, moment(date).format(constants.dateFormat))}
                {renderField(<IconSVG name="timer" width="16" height="16" />, `${dateTimeUtils.timestampFromUtcString(bidsDue, timeZone)} - ${dateTimeUtils.timestampFromUtcString(goodUntil, timeZone)}`)}
                {renderField('Days To Settle:', daysToSettle)}
            </ul>
        </div>
    );
}
