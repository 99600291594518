import { processActions } from '../../actions';
import { OpenBiddingSettingsSection } from './sections/OpenBiddingSettingsSection';
import { JumpBallSection } from './sections/JumpBallSection';
import { OnHoverTooltip } from '../common';
import IconSVG from '../../styles/svg-icons';
import { BwicDateTimeSection } from '../process/BwicDateTimeSection';
import { Checkbox } from '../controls/Checkbox';
import { BwicProcessType } from '../../types/models/Process';
import { LevelSpecificationType } from '../../types/enums/LevelSpecificationType';
import { useAppSelector } from '../../effects/useAppSelector';
import { TopXSection } from './sections/TopXSection';
import { BestFootForwardSection } from './sections/BestFootForwardSection';
import { StandartBiddingSection } from './sections/StandartBiddingSection';
import { useAppDispatch } from '../../effects/useAppDispatch';

export function TemplateDetails() {
    const dispatch = useAppDispatch();
    const template = useAppSelector(s => s.process.templates.current);
    const errors = useAppSelector(s => s.process.errors.templates);

    if (!template) {
        return null;
    }

    return (
        <div className="content-part-sidebar flex-item flex-item-template container-flex">
            <BwicDateTimeSection />
            <div className="flex-row flex-row-item flex-item-template-title">
                <h2>Bidding Process <span className="name-tag">{template.title}</span></h2>
                <div className="flex-item-right flex-none">
                    <ResetChangesButton />
                </div>
            </div>
            <div className="flex-item-template-cnt container-flex">
                <h2>General</h2>

                <div className="section-row flex-row">
                    <div className="section-row-cnt options">
                        <div className="flex-row options-row">
                            <Checkbox
                                checked={template.reserveLevelsApply}
                                onChange={e => dispatch(processActions.reserveLevelsApplyEnabled(e.target.checked))}
                                label="Reserve levels apply"
                            />
                            <OnHoverTooltip overlay="Check box to notify bidders that you maintain reserve levels for each security">
                                <IconSVG name="info" width={16} height={16} />
                            </OnHoverTooltip>
                        </div>
                        <div className="flex-row options-row">
                            <Checkbox
                                checked={template.earlyTrades}
                                onChange={e => dispatch(processActions.earlyTradesEnabled(e.target.checked))}
                                label="Can trade early"
                            />
                            <OnHoverTooltip overlay="Check box to notify bidders that securities are free to trade before the BWIC start">
                                <IconSVG name="info" width={16} height={16} />
                            </OnHoverTooltip>
                        </div>
                    </div>
                    <div className="section-row-cnt notes">
                        <label className="form-label">Notes (optional)</label>
                        <textarea
                            className="form-control"
                            value={template.rulesText || ''}
                            placeholder="Enter Notes"
                            maxLength={512}
                            cols={60}
                            rows={8}
                            onChange={e => dispatch(processActions.rulesText(e.target.value))}
                        />
                    </div>
                </div>
                {template?.type === BwicProcessType.Standard && <StandartBiddingSection />}
                {
                    template?.type === BwicProcessType.Live &&
                    <OpenBiddingSettingsSection
                        enabled={template.type === BwicProcessType.Live}
                        stage1Deadline={template?.liveBidding?.stage1Deadline}
                        improvementRound={template?.liveBidding?.improvementRound}
                        minBidIncrement={template?.liveBidding?.minBidIncrement}
                        levelSpecification={template?.liveBidding?.levelSpecificationType}
                        openBiddingImproverCount={template?.liveBidding?.openBiddingImproverCount}
                        stage1DeadlineError={errors?.stage1Deadline}
                        improvementRoundError={errors?.improvementRound}
                        minBidIncrementError={errors?.minBidIncrement}
                        resetStage1DeadlineError={() => dispatch(processActions.resetStage1DeadlineError())}
                        resetImprovementRoundError={() => dispatch(processActions.resetImprovementRoundError())}
                        resetMinBidIncrementError={() => dispatch(processActions.resetMinBidIncrementError())}
                        onChangeStage1Deadline={(value: string) => dispatch(processActions.setStage1Deadline(value))}
                        onChangeImprovementRound={(value: string) => dispatch(processActions.setImprovementRound(value))}
                        onChangeMinBidIncrement={(value: string) => dispatch(processActions.setMinBidIncrement(value))}
                        onChangeLevelSpecification={(value: LevelSpecificationType) => dispatch(processActions.setLevelSpecificationType(value))}
                        onChangeOpenBiddingImproverCount={(value: number) => dispatch(processActions.setOpenBiddingImproverCount(value))}
                    />
                }
                {template?.type === BwicProcessType.JumpBall && <JumpBallSection />}
                {template?.type === BwicProcessType.TopX && <TopXSection />}
                {template?.type === BwicProcessType.BestFootForward && <BestFootForwardSection />}
            </div>
        </div>
    );
}

function ResetChangesButton() {
    const dispatch = useAppDispatch();
    const isTemplateDirty = useAppSelector(s => s.process.templates.dirty);

    if (isTemplateDirty) {
        return (
            <div className="modify">
                <span className="text-warm-grey text-sm notify">Process was modified</span>
                <button
                    className="btn-link"
                    onClick={() => dispatch(processActions.resetChanges())}
                >
                    <IconSVG name="close" width={16} height={16} /> Reset to default
                </button>
            </div>
        );
    }

    return null;
}
