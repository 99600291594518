import React from 'react'
import { SettingsLine } from './SettingsLine';

export function CutoffReminderSettingsLine({ children }: { children: React.ReactNode }) {
    return (
        <SettingsLine
            title={<>Reminder to bid <span className="text-red">*</span></>}
            tooltip="Reminder to bid — the notification will be sent to BWIC participants who did not bid. A field is the number of minutes to the end of bidding period"
        >
            <>{children}</>
        </SettingsLine>
    );
}
