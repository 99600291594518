import React, { useEffect } from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FormControlWrapper, RadioButton, RadioButtonGroup } from '../../controls';
import { FlexRow, OnHoverTooltip } from '../../common';
import { levelSpecifications } from '../../../constants';
import { formatUtils } from "../../../utils/format.utils";
import { constants } from "../../../constants/constants";
import IconSVG from '../../../styles/svg-icons';
import { CutOffReminder } from '../CutOffReminder';
import { CutoffReminderSettingsLine } from '../CutoffReminderSettingsLine';
import { LevelSpecificationType } from '../../../types/enums/LevelSpecificationType';
import { OpenBiddingImproverCount } from '../../../types/enums/OpenBiddingImproverCount';

export function OpenBiddingSettingsSection(
    {
        enabled,
        stage1Deadline, onChangeStage1Deadline,
        improvementRound, onChangeImprovementRound,
        minBidIncrement, onChangeMinBidIncrement,
        levelSpecification, onChangeLevelSpecification,
        openBiddingImproverCount, onChangeOpenBiddingImproverCount,
        stage1DeadlineError, resetStage1DeadlineError,
        improvementRoundError, resetImprovementRoundError,
        minBidIncrementError, resetMinBidIncrementError
    }
) {
    useEffect(() => {
        if (!enabled) {
            resetStage1DeadlineError();
            resetImprovementRoundError();
        }// eslint-disable-next-line
    }, [enabled]);

    useEffect(() => {
        if (enabled && levelSpecification == null) {
            onChangeLevelSpecification && onChangeLevelSpecification(levelSpecifications.best.key);
        }
    }, [levelSpecification, enabled, onChangeLevelSpecification]);

    useEffect(() => {
        if (enabled && openBiddingImproverCount == null) {
            onChangeOpenBiddingImproverCount?.(OpenBiddingImproverCount.top3);
        }
    }, [enabled, openBiddingImproverCount, onChangeOpenBiddingImproverCount]);

    const openBiddingImproverCountList = [
        { label: "Top 2", value: OpenBiddingImproverCount.top2 },
        { label: "Top 3", value: OpenBiddingImproverCount.top3 },
        { label: "Top 4", value: OpenBiddingImproverCount.top4 },
        { label: "Top 5", value: OpenBiddingImproverCount.top5 }
    ];

    function handleChangeBlindBiddingTimeout(e) {
        onChangeStage1Deadline && onChangeStage1Deadline(e.target.value);
    }

    function handleChangeOpenBiddingTimeout(e) {
        onChangeImprovementRound && onChangeImprovementRound(e.target.value);
    }

    function handleChangeMinBidIncrement(e) {
        const { value } = e.target;
        if (value && !(constants.bidNumber.test(value))) {
            return null;
        }
        onChangeMinBidIncrement && onChangeMinBidIncrement(value);
    }

    function handleBlurMinBidIncrement(e) {
        onChangeMinBidIncrement && onChangeMinBidIncrement(formatUtils.formatBid(e.target.value.trim()));
        resetMinBidIncrementError && resetMinBidIncrementError();
    }

    return (
        <div className="stages">
            <h2>
                Stage 1 &#8212; Blind Bidding
                <OnHoverTooltip overlay="A selected number of bidders are given the ability to improve further and receive
                    transparency on either the current Best price. Improvement rounds are limited by time for each security. 
                    If a security Best changes during the improvement round, the timer for this security resets. 
                    If no improvement is received during the improvement interval no further bids will be accepted for that security. 
                    Bidders must improve by an amount greater or equal to minimum improvement over the current Best price.">
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </h2>
            <div className="section-row flex-row">
                <div className="section-row-title">
                    <label className="form-label">Stage 1 duration <span className="text-red">*</span></label>
                </div>
                <div className="section-row-cnt">
                    <FlexRow>
                        <FormControlWrapper error={enabled && stage1DeadlineError}>
                            <MaskedInput
                                className={classNames('form-control range', { 'is-invalid': enabled && stage1DeadlineError })}
                                type="text"
                                mask={createNumberMask({ prefix: '', integerLimit: 999 })}
                                value={stage1Deadline || ''}
                                onChange={handleChangeBlindBiddingTimeout}
                                onBlur={resetStage1DeadlineError}
                                disabled={!enabled}
                                maxLength={3}
                            />
                        </FormControlWrapper>
                        <span className="info-label">
                            Minutes
                        </span >
                    </FlexRow>
                </div>
            </div>
            <CutoffReminderSettingsLine>
                <CutOffReminder />
            </CutoffReminderSettingsLine>
            <div className="section-row flex-row">
                <div className="section-row-title">
                    <label className="form-label">
                        # of Stage 2 participants
                        <OnHoverTooltip overlay="Seller defines who is invited to the Stage 2 with LiveBidding&#8482;. This limitation incentivizes bidders to show their best levels earlier in the process.">
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    </label>
                </div>
                <div className="section-row-cnt">
                    <FlexRow>
                        <RadioButtonGroup
                            name="participants"
                            options={openBiddingImproverCountList}
                            value={openBiddingImproverCount || OpenBiddingImproverCount.top3}
                            onChange={onChangeOpenBiddingImproverCount}
                            disabled={!enabled}
                        />
                    </FlexRow>
                </div>
            </div>

            <h2>
                Stage 2 &#8212; LiveBidding&#8482;
                <OnHoverTooltip overlay="A selected number of bidders are given the ability to improve further and receive
                    transparency on either the current Best price. Improvement rounds are limited by time for each security. 
                    If a security Best changes during the improvement round, the timer for this security resets. 
                    If no improvement is received during the improvement interval no further bids will be accepted for that security. 
                    Bidders must improve by an amount greater or equal to minimum improvement over the current Best price.">
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </h2>
            <div className="section-row flex-row">
                <div className="section-row-title">
                    <label className="form-label">
                        Improvement round duration <span className="text-red">*</span>
                        <OnHoverTooltip overlay="Eligible participants will see the Best level and can submit improvements during 
                            a specified interval. Improvement round resets if the Best price changes. 
                            If Best does not change during a specified interval
                            no further bids will be accepted.">
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    </label>
                </div>
                <div className="section-row-cnt">
                    <FlexRow>
                        <FormControlWrapper error={enabled && improvementRoundError}>
                            <MaskedInput
                                className={classNames('form-control range', { 'is-invalid': enabled && improvementRoundError })}
                                type="text"
                                mask={createNumberMask({ prefix: '', integerLimit: 999 })}
                                value={improvementRound || ''}
                                onChange={handleChangeOpenBiddingTimeout}
                                onBlur={resetImprovementRoundError}
                                disabled={!enabled}
                                maxLength={3}
                            />
                        </FormControlWrapper>
                        <span className="info-label">
                            Minutes
                        </span >
                    </FlexRow>
                </div>
            </div>
            <div className="section-row flex-row">
                <div className="section-row-title">
                    <label className="form-label">
                        Minimum bid increment <span className="text-red">*</span>
                        <OnHoverTooltip overlay="Minimal improvement over Best price accepted during Step 2 of the BWIC. Bidders will not be able to submit levels below Best plus Minimum Improvement.">
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    </label>
                </div>
                <div className="section-row-cnt">
                    <FlexRow>
                        <FormControlWrapper error={enabled && minBidIncrementError}>
                            <MaskedInput
                                className={classNames('form-control range', { 'is-invalid': enabled && minBidIncrementError })}
                                value={minBidIncrement || ''}
                                mask={createNumberMask({
                                    prefix: "",
                                    suffix: "",
                                    allowDecimal: true,
                                    includeThousandsSeparator: false,
                                    integerLimit: 2,
                                    decimalLimit: 4,
                                })}
                                onChange={handleChangeMinBidIncrement}
                                onBlur={handleBlurMinBidIncrement}
                                disabled={!enabled}
                            />
                            <span className="info-label">
                                Bps
                            </span >
                        </FormControlWrapper>
                    </FlexRow>
                </div>
            </div>
            <div className="section-row flex-row">
                <div className="section-row-title">
                    <label className="form-label">
                        Price type
                        <OnHoverTooltip overlay="The seller defines which price will be visible to invited participants in Stage 2. Prices will be updated in real-time.">
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    </label>
                </div>
                <div className="section-row-cnt">
                    <FlexRow>
                        <RadioButton
                            className="radio-group"
                            name="price-type"
                            key={levelSpecifications.best.key}
                            label={levelSpecifications.best.title}
                            value={levelSpecifications.best.key}
                            checked={levelSpecifications.best.key === LevelSpecificationType.best}
                            onChange={onChangeLevelSpecification}
                            disabled={!enabled}
                        />
                    </FlexRow>
                </div>
            </div>
        </div>
    )
}
