import classNames from 'classnames';
import { TopXStage2Participants } from '../../../types/bwic-process/TopXSettings';
import { FormError, RadioButton } from '../../controls';
import { processActions } from '../../../actions';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useAppSelector } from '../../../effects/useAppSelector';
import { dateTimeUtils } from '../../../utils';
import { CutOffReminder } from '../CutOffReminder';
import { SettingsLine } from '../SettingsLine';
import { AutoFeedbackDelay } from '../AutoFeedbackDelay';
import { CutoffReminderSettingsLine } from '../CutoffReminderSettingsLine';
import { MinBidIncrementSettingsLine } from '../MinBidIncrementSettingsLine';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { BwicManualStageEndingInfo } from '../BwicManualStageEndingInfo';
import { BwicStageEndType } from '../../../types/bwic-process/BwicStageEndType';
import { BwicStageEndSelect } from '../BwicStageEndSelect';

export function TopXSection() {
    return (
        <div className="stages">
            <SettingsLine
                title="Autofeedback delay"
                tooltip="The seller can set a delay in sending auto-feedbacks for all bidders. Delay information will not be disclosed to bidders"
            >
                <AutoFeedbackDelay />
            </SettingsLine>
            <MinBidIncrementSettingsLine />
            <h2>Stage 1 — Open bidding with autofeedback</h2>
            <SettingsLine
                title="End Stage 1"
                tooltip={<>The Seller can determine the mode in which the Stage 1 of the BWIC will be completed. Automatically - Stage 1 will be completed automatically according to the time specified in 'Stage 1 duration'.<br />Manually - the Seller manually determines the end of Stage 1 by clicking on the button.</>}
            >
                <Stage1End />
            </SettingsLine>
            <TopXCutoffReminder />
            <SettingsLine
                title="# of Stage 2 participants"
                tooltip="Seller defines who is invited to the Stage 2 with Top X. This limitation incentivizes bidders to show their best level earlier in the process."
            >
                <Stage2Participants />
            </SettingsLine>
            <h2>Stage 2 — Top X bidders have the ability to improve their bids many times</h2>
            <SettingsLine
                title="End Stage 2"
                tooltip={<>If &#34;Manually&#34; - Stage 2 will end according to the end time of BWIC.<br />If &#34;Automatically&#34; -  Stage 2 will end according to the set time.</>}
            >
                <Stage2Duration />
            </SettingsLine>
        </div>
    );
}

function TopXCutoffReminder() {
    const automaticStage1DeadlineTimeSpan = useAppSelector(s => s.process.templates.current?.topX?.automaticStage1Deadline);

    return (
        <CutoffReminderSettingsLine>
            <CutOffReminder disabled={!automaticStage1DeadlineTimeSpan} />
        </CutoffReminderSettingsLine>
    )
}

function Stage1End() {
    const dispatch = useAppDispatch();

    const automaticStage1DeadlineTimeSpan = useAppSelector(s => s.process.templates.current?.topX?.automaticStage1Deadline);
    const automaticStage1Deadline = automaticStage1DeadlineTimeSpan
        ? dateTimeUtils.parseTimeSpan(automaticStage1DeadlineTimeSpan).totalMinutes
        : undefined;
    const isManual = automaticStage1DeadlineTimeSpan == null;
    const error = useAppSelector(s => s.process.errors.templates.topXStage1AutomaticMinutes)


    return (
        <>
            <BwicStageEndSelect
                selectedType={isManual ? BwicStageEndType.Manually : BwicStageEndType.Automatically}
                onChange={(option) => {
                    if (option === BwicStageEndType.Manually) {
                        dispatch(processActions.topXStage1EndManual());
                        dispatch(processActions.resetTopXStage1AutomaticMinutes());
                    } else {
                        dispatch(processActions.topXStage1EndAuto());
                    }
                }}
            />
            {isManual ? (
                <BwicManualStageEndingInfo stage={1} />
            ) : (
                <>
                    <label className="form-label">Stage 1 duration <span className="text-red">*</span></label>
                    <div className="form-control-wrapper">
                        <MaskedInput
                            value={String(automaticStage1Deadline || "")}
                            className={classNames(
                                'form-control range',
                                { 'is-invalid': error }
                            )}
                            maxLength={3}
                            mask={createNumberMask({
                                prefix: "",
                                suffix: "",
                                allowDecimal: false,
                                includeThousandsSeparator: false,
                                integerLimit: 3,
                            })}
                            onChange={e => dispatch(processActions.topXStage1EndAutomaticMinutesChange(e.target.value))}
                        />
                        <span className="info-label">
                            Minutes
                        </span >
                        <FormError message={error} />
                    </div>
                </>
            )}
        </>
    );
}

function Stage2Participants() {
    const dispatch = useAppDispatch();

    const participants = useAppSelector(s => s.process.templates.current?.topX?.improverCount)
        ?? TopXStage2Participants.Top3;

    return (
        <div className="radio-group">
            <RadioButton
                label="Top 2"
                name="stage-2-participants"
                checked={participants === TopXStage2Participants.Top2}
                onChange={() => dispatch(processActions.topXStage2ParticipantsChange(TopXStage2Participants.Top2))}
            />
            <RadioButton
                label="Top 3"
                name="stage-2-participants"
                checked={participants === TopXStage2Participants.Top3}
                onChange={() => dispatch(processActions.topXStage2ParticipantsChange(TopXStage2Participants.Top3))}
            />
            <RadioButton
                label="Top 4"
                name="stage-2-participants"
                checked={participants === TopXStage2Participants.Top4}
                onChange={() => dispatch(processActions.topXStage2ParticipantsChange(TopXStage2Participants.Top4))}
            />
            <RadioButton
                label="Top 5"
                name="stage-2-participants"
                checked={participants === TopXStage2Participants.Top5}
                onChange={() => dispatch(processActions.topXStage2ParticipantsChange(TopXStage2Participants.Top5))}
            />
        </div>
    );
}

function Stage2Duration() {
    const dispatch = useAppDispatch();

    const improvementRoundTimeSpan = useAppSelector(s => s.process.templates.current?.topX?.improvementRound);
    const improvementRound = improvementRoundTimeSpan
        ? dateTimeUtils.parseTimeSpan(improvementRoundTimeSpan).totalMinutes
        : undefined;
    const isManual = improvementRoundTimeSpan == null;
    const error = useAppSelector(s => s.process.errors.templates.topXStage2DurationMinutes)

    return (
        <>
            <BwicStageEndSelect
                selectedType={isManual ? BwicStageEndType.Manually : BwicStageEndType.Automatically}
                onChange={(option) => {
                    if (option === BwicStageEndType.Manually) {
                        dispatch(processActions.topXStage2DurationUnspecified());
                        dispatch(processActions.resetTopXStage2DurationMinutes());
                    } else {
                        dispatch(processActions.topXStage2DurationPreset());
                    }
                }}
            />
            {isManual ? (
                <BwicManualStageEndingInfo stage={2} />
            ) : (
                <>
                    <label className="form-label">Stage 2 duration <span className="text-red">*</span></label>
                    <div className="form-control-wrapper">
                        <MaskedInput
                            value={String(improvementRound || "")}
                            className={classNames(
                                'form-control range',
                                { 'is-invalid': error }
                            )}
                            maxLength={3}
                            mask={createNumberMask({
                                prefix: "",
                                suffix: "",
                                allowDecimal: false,
                                includeThousandsSeparator: false,
                                integerLimit: 3,
                            })}
                            onChange={e => dispatch(processActions.topXStage2DurationMinutesChange(e.target.value))}
                        />
                        <span className="info-label">
                            Minutes
                        </span >
                        <FormError message={error} />
                    </div>
                </>
            )}
        </>
    );
}
