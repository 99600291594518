import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popup } from '../controls';
import { onBoardingTooltipSetVisibleSupportPopup } from '../../actions';
import contentImage from '../../images/onboarding-support.png';

export function OnBoardingTooltipSupportPopup() {
    const dispatch = useDispatch();
    const stateBoardingTooltip = useSelector((s) => s.onBoardingTooltip);
    const { visibleSupportPopup } = stateBoardingTooltip;

    const cancel = () => {
        dispatch(onBoardingTooltipSetVisibleSupportPopup(false))
    };

    if (visibleSupportPopup) {
        return (
            <Popup
                title="Tooltip Mode"
                modalClass="modal-onboarding-support"
                onClose={cancel}
            >
                <div className="modal-body">
                    <img src={contentImage} alt=""/>
                </div>
                <div className="modal-footer">
                    <p className="text-sm">You can reopen Tooltip Mode from the ‘Help &amp; Support’ icon on the Header</p>
                    <button type="button" className="btn btn-main" onClick={cancel}>Close</button>
                </div>
            </Popup>
        )
    }
    return null;
}
