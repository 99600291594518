import React, { useState } from 'react';
import IconSVG from '../../../styles/svg-icons';
import { PortfolioAlertsPopup } from '../../profile/tabs/email-preferences/tabs/portfolio/PortfolioAlertsPopup';
import { useAppSelector } from '../../../effects/useAppSelector';
import { useEmailPreferences } from '../../../effects/data-accessors/useEmailPreferences';
import { AlertEnabledState } from '../../../types/enums/AlertEnabledState';
import { usePageConfig } from '../../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { PortfolioUserConfigFilter } from '../../../types/user-config/UserConfigFilter';
import { AlertOption } from '../../../types/email-preferences/EmailPreferences';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { user } from '../../../user';

interface Props {
    disabled: boolean;
}

export const PortfolioAlertsPreferences = ({ disabled }: Props) => {
    const [showModal, setShowModal] = useState(false);

    const hasAccess = user.hasAllFeatures(...[
        SubscriptionFeature.IssuanceMonitorFullAccess,
        SubscriptionFeature.PortfolioAlerts,
    ]);

    const hasSavedFiltersAccess = user.hasAllFeatures(...[SubscriptionFeature.PortfolioSavedFilters]);

    const isSomePortfolioAlertsEnabled = useAppSelector(s =>
        s.entities.emailPreferences.preferences?.portfolioBwicAlertState !== AlertEnabledState.Disabled ||
        s.entities.emailPreferences.preferences?.portfolioDealersInventoryAlertState !== AlertEnabledState.Disabled ||
        (hasAccess && s.entities.emailPreferences.preferences?.portfolioIssuanceMonitorAlertState !== AlertEnabledState.Disabled) ||
        (hasAccess && s.entities.emailPreferences.preferences?.portfolioOutOfRiPeriodAlertState !== AlertEnabledState.Disabled) ||
        (hasAccess && s.entities.emailPreferences.preferences?.portfolioOutOfNcPeriodAlertState !== AlertEnabledState.Disabled) ||
        (hasAccess && s.entities.emailPreferences.preferences?.portfolioRollerDeadlineAlertState !== AlertEnabledState.Disabled)
    );
    const { config } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const isAnyFilterAlertEnabled = hasSavedFiltersAccess && config?.filters.some(f =>
        (f as PortfolioUserConfigFilter).bwicAlertOption !== AlertOption.Never ||
        (f as PortfolioUserConfigFilter).dealerInventoryAlertOption !== AlertOption.Never
    );
    const isAnyAlertEnabled = isAnyFilterAlertEnabled || isSomePortfolioAlertsEnabled;

    useEmailPreferences();

    return (
        <>
            <button
                disabled={disabled}
                className="btn btn-link"
                onClick={() => setShowModal(true)}
            >
                <IconSVG name={isAnyAlertEnabled ? "alert" : "alert-off"} width={16} height={16} />
                Alerts
            </button>
            {showModal && <PortfolioAlertsPopup onClose={() => setShowModal(false)} />}
        </>
    );
}
