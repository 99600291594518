import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { constants } from '../../constants';
import { NotificationType } from '../../types/notifications/NotificationType';
import { notificationActions } from '../../actions';
import { Notification } from '../../types/notifications/Notification';
import { NotificationBody } from './NotificationBody';
import IconSVG from '../../styles/svg-icons';
import { ModalNotification } from './ModalNotification';
import { logger } from '../../logging/logger';
import { useAppSelector } from '../../effects/useAppSelector';

export const Notifications: React.FC = () => {
    const dispatch = useDispatch();

    const mounted = useRef(false);
    const timeouts = useRef<{ [referenceName: string]: NodeJS.Timeout }>({});

    const notificationAttributes = useAppSelector(s => s.notification.notificationAttributes)
    const allNotifications = useAppSelector(s => s.notification.notificationList)
    const visibleNotifications = allNotifications.filter(n =>
        notificationAttributes[n.referenceName]?.toastVisible
    )
    const modalNotificationList = useAppSelector(s => s.notification.modalNotificationList);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, [mounted]);

    const handleCloseNotification = (referenceName: string) => {
        if (timeouts.current[referenceName]) {
            clearTimeout(timeouts.current[referenceName]);
        }
        dispatch(notificationActions.notificationListHideToast(referenceName));
    };

    const renderNotification = (notification: Notification, i: number) => {
        const { subject, message } = notification;
        const { type } = notificationAttributes[notification.referenceName];
        const iconClass = type === NotificationType.Error ? 'icon-warning-toast' : 'icon-bell-toast';

        logger.trace("Show toast notification", notification);

        return (
            <div
                key={i}
                className={cn('notification', { 'notification-error': type === NotificationType.Error })}
            >
                <div className="notification-icon">
                    <i className={cn('icon icon-xl', iconClass)} />
                </div>
                <div className="notification-content">
                    <div className="notification-subject">{subject}</div>
                    <NotificationBody text={message} />
                </div>
                <button
                    className="notification-close btn-close"
                    onClick={() => handleCloseNotification(notification.referenceName)}
                >
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
        )
    };

    const autoHideNotification = (referenceName: string) => {
        if (!timeouts.current[referenceName]) {
            timeouts.current[referenceName] = setTimeout(() => {
                dispatch(notificationActions.notificationListToastExpired(referenceName));
            }, constants.notificationDisplayTimeout)
        }
    };

    const renderNotificationList = () => {
        const renderedList = [];
        for (let i = 0; i < Math.min(visibleNotifications.length, constants.notificationListDisplayLimit); i++) {
            renderedList.push(renderNotification(visibleNotifications[i], i));
            autoHideNotification(visibleNotifications[i].referenceName)
        }
        return renderedList;
    };

    const renderModalNotificationList = () => {
        let firstTitleAnimationIndex = modalNotificationList.findIndex(n => n.blinkBrowserTabText?.length);

        return modalNotificationList.map((n, i) => {
            logger.trace("Show modal notification", n);

            return <ModalNotification
                key={i}
                // Animate browser tab title for the single notification
                notification={i === firstTitleAnimationIndex ? n : { ...n, blinkBrowserTabText: [] }}
                onClose={() => dispatch(notificationActions.notificationListRemoveModalItem(i))}
            />
        });
    }

    return (
        <div className="notifications">
            {renderNotificationList()}
            {renderModalNotificationList()}
        </div>
    );
};
