import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../../actions/notification.actions';
import { AppState } from '../../../types/state/AppState';
import { NotificationList } from './NotificationList';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { isRequesting, isRequestNone, isRequestSuccess } from '../../../utils';
import IconSVG from '../../../styles/svg-icons';
import classNames from 'classnames';
import { notificationUtils } from '../../../utils/notifications.utils';
import { Relative } from '../../common';

const NotificationPopoverContent: React.FC = () => {
    const minItemsInList = 20;
    const dispatch = useDispatch();
    const btnRef = useRef<HTMLButtonElement | null>(null);

    const [visibleList, setVisibleList] = useState(false);

    const connectionStatus = useSelector((state: AppState) => state.notification.connectionStatus);
    const countUnreadNotifications = useSelector((state: AppState) => state.notification.countUnreadNotifications);
    const requestStateGetNotificationList = useSelector((state: AppState) => state.notification.requestStateGetNotificationList);
    const notifications = useSelector((state: AppState) => state.notification.notificationList);
    const notificationAttributes = useSelector((state: AppState) => state.notification.notificationAttributes);

    const visibleNotifications = notifications.filter(n => notificationAttributes[n.referenceName]?.displayInNotificationList);
    const isSomeUnreadNotificationInStore = visibleNotifications.some(n => !n.isReadByUser);

    useEffect(() => {
        if (visibleList && notificationUtils.isConnected(connectionStatus) && isRequestNone(requestStateGetNotificationList)) {
            dispatch(notificationActions.notificationGetList());
        }
    }, [connectionStatus, dispatch, visibleList, requestStateGetNotificationList]);

    const handleButtonClick = () => {
        if (visibleList) {
            setVisibleList(false);
        } else {
            setVisibleList(true);
            if (visibleNotifications.length < minItemsInList && notificationUtils.isConnected(connectionStatus)) {
                dispatch(notificationActions.notificationGetList());
            }
        }
    };

    const handleHide = (e: MouseEvent) => {
        if (!btnRef.current?.contains(e.target as Node)) {
            setVisibleList(false);
        }
    };

    return (
        <Relative>
            <OnHoverTooltip overlay="Notifications">
                <button
                    ref={btnRef}
                    className={classNames('navbar-link navbar-link-notifications', { 'is-show': visibleList })}
                    disabled={isRequesting(requestStateGetNotificationList)}
                    onClick={handleButtonClick}
                >
                    <IconSVG name="notifications" width={24} height={24} />
                    {(!!countUnreadNotifications || isSomeUnreadNotificationInStore) && <span className="alert-badge" /> }
                </button>
            </OnHoverTooltip>
            <NotificationList
                onHide={handleHide}
                visible={visibleList}
                notifications={visibleNotifications}
            />
        </Relative>
    )
};

export function NotificationPopover() {
    const dispatch = useDispatch();
    const connectionStatus = useSelector((state: AppState) => state.notification.connectionStatus);
    const requestStateGetCountUnread = useSelector((state: AppState) => state.notification.requestStateGetNotificationList);
    useEffect(() => {
        if (
            notificationUtils.isConnected(connectionStatus) &&
            !isRequesting(requestStateGetCountUnread) &&
            !isRequestSuccess(requestStateGetCountUnread)
        ) {
            dispatch(notificationActions.notificationsGetCountUnread())
        }
    }, [dispatch, connectionStatus, requestStateGetCountUnread]);
    return <NotificationPopoverContent />;
}
