import React, { Component } from 'react';
import { connect } from 'react-redux';
import { processActions as actions } from '../../actions';
import { TimePickerControl } from '../controls';
import { classnames } from '../../styles/classnames';
import { dateTimeUtils } from '../../utils';
import { appConfig } from '../../app-config';


class Time extends Component {
    handleBidsDueChange = time => this.props.dispatch(actions.changeBidsDueTime(time));

    handleGoodUntilChange = time => this.props.dispatch(actions.changeGoodUntilTime(time));

    render = () => {
        const { bidsDue, goodUntil, error, disabledHours, isBidsDueMinutesDisabled, isGoodUntilMinutesDisabled } = this.props;

        if (!disabledHours) return null;

        const isInvalid = classnames({ 'is-invalid': !!error });
        const disabledMinutes = [15, 30, 45];

        return (
            <>
                <div className="display-inline">
                    <label className="form-label">Start Time</label>
                    <TimePickerControl
                        id="bidDue"
                        time={bidsDue}
                        defaultTime={dateTimeUtils.timestampToUtcString('10:00 AM')}
                        onTimeChange={this.handleBidsDueChange}
                        className={isInvalid}
                        disabledHours={appConfig.enableAnyTimeNewBwic ? undefined : disabledHours}
                        disabledMinutes={isBidsDueMinutesDisabled && !appConfig.enableAnyTimeNewBwic ? disabledMinutes : []}
                    />
                </div>
                <span className="separator"> — </span>
                <div className="display-inline">
                    <label className="form-label">End Time</label>
                    <TimePickerControl
                        id="goodUntil"
                        time={goodUntil}
                        defaultTime={dateTimeUtils.timestampToUtcString('12:00 PM')}
                        onTimeChange={this.handleGoodUntilChange}
                        className={isInvalid}
                        disabledHours={appConfig.enableAnyTimeNewBwic ? undefined : disabledHours}
                        disabledMinutes={isGoodUntilMinutesDisabled && !appConfig.enableAnyTimeNewBwic ? disabledMinutes : []}
                    />
                </div>
                { !!error && <div className="form-error">{error}</div> }
            </>
        );
    }
}

const mapStateToProps = ({ process }) => ({
    bidsDue: process.bidsDue,
    goodUntil: process.goodUntil,
    disabledHours: process.disabledHours,
    isBidsDueMinutesDisabled: process.isBidsDueMinutesDisabled,
    isGoodUntilMinutesDisabled: process.isGoodUntilMinutesDisabled,
    error: process.errors && process.errors.time
});


const connectedTime = connect(mapStateToProps)(Time);
export { connectedTime as Time };
