import React from 'react';
import { user } from '../../user';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { PortfolioAgreementDisclaimerContext } from './PortfolioAgreementDisclaimerContext';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { isRequesting } from '../../utils';
import { User } from '../../types/account/User';

interface Props {
    children: (blocked: boolean) => React.ReactNode;
    disabled: boolean;
}

export const PortfolioAgreementDisclaimer: React.FC<Props> = ({ children, disabled }) => {
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const confirmRequestState = useSelector((state: AppState) => state.portfolioAgreementDisclaimer.confirmRequestState);
    const currentUser: User | null = user.current()
    const blocked =
        !disabled &&
        !isFeatureBlocked &&
        !currentUser?.portfolioDisclaimerAgreed &&
        !isRequesting(confirmRequestState);

    return (
        <PortfolioAgreementDisclaimerContext.Provider value={blocked}>
            {children(blocked)}
        </PortfolioAgreementDisclaimerContext.Provider>
    )
}
