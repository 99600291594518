import { BwicStageEndType } from "../../types/bwic-process/BwicStageEndType";

interface Props {
    selectedType: BwicStageEndType;
    onChange: (value: string) => void;
}

export function BwicStageEndSelect({ selectedType, onChange }: Props) {
    return (
        <select className="form-control form-select" value={selectedType} onChange={(e) => onChange(e.target.value)}>
            {Object.keys(BwicStageEndType).map((t) => (
                <option key={t}>{t}</option>
            ))}
        </select>
    );
}
