import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { processActions as actions } from '../../actions';
import { Counter } from '../controls';


class DaysToSettle extends Component {
    render = () => {
        const { daysToSettle } = this.props;

        return (
            <Fragment>
                <label className="form-label">Days to Settle</label>
                <Counter
                    className="control-stepper-sm"
                    min={0}
                    max={5}
                    emptyEnabled={false}
                    value={daysToSettle}
                    onValueChanged={this.handleDaysChanged}
                />
            </Fragment>
        );
    }

    handleDaysChanged = days => this.props.dispatch(actions.changeDaysToSettle(days));
}

const mapStateToProps = ({ process }) => ({
    daysToSettle: process.daysToSettle,
});


const connectedDaysToSettle = connect(mapStateToProps)(DaysToSettle);
export { connectedDaysToSettle as DaysToSettle };
