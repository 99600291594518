import { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from '../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../controls/breadcrumbs/BreadcrumbsItem';
import { routes } from '../../constants';
import { portfolioDocumentsActions as actions } from '../../actions';
import { history } from '../../history';
import { ManageDealDocuments } from '../documents/ManageDealDocuments';

class PortfolioDocuments extends Component {
    componentDidMount = () => this.props.dispatch(actions.init());

    componentWillUnmount = () => this.props.dispatch(actions.reset());

    render = () => {
        const { isUploading } = this.props;

        return (
            <div className="portfolio container">
                <div className="sub-header">
                    <div className="sub-header-row type01 flex-row">
                        <Breadcrumbs>
                            <BreadcrumbsItem route={routes.portfolio} text='Portfolio' />
                        </Breadcrumbs>
                        <h1>Upload Documents</h1>
                        <div className="flex-item-right controls">
                            <button
                                className="btn btn-ghost"
                                onClick={() => history.push(routes.portfolio)}
                                disabled={isUploading || !this.props.isInitialized}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <ManageDealDocuments />
            </div>
        );
    };
}

const mapStateToProps = ({ uploadDocuments }) => ({
    isUploading: uploadDocuments.isUploading,
    isInitialized: uploadDocuments.isInitialized
});

const connectedPortfolioDocuments = connect(mapStateToProps)(PortfolioDocuments);
export { connectedPortfolioDocuments as PortfolioDocuments };

