import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { editPortfolioActions as actions, gridActions, securitiesActions } from '../../actions';
import { routes, uploadStatus } from '../../constants';
import { Grid } from '../grid';
import { RouteLeavingGuard } from '../routing';
import { Confirm } from '../alerts';
import { Preloader } from '../common';
import { OnHoverTooltip } from '../common';
import { RequestState } from '../../constants/request-state';
import { Breadcrumbs } from '../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../controls/breadcrumbs/BreadcrumbsItem';
import IconSVG from '../../styles/svg-icons';
import { DownloadTemplateButton } from '../common/download-template/DownloadTemplateButton';
import { useHistory, useParams } from 'react-router';
import { securitiesComparer } from '../../utils';
import { PortfolioNameDialog } from './PortfolioNameDialog';
import { DeleteRowsWithErrorButton } from '../grid/DeleteRowsWithErrorButton';
import { SecuritiesGridFlaggedPopup } from '../common/SecuritiesGridFlaggedPopup';
import { DeleteFlaggedItemsButton } from '../grid/DeleteFlaggedItemsButton';
import { DeleteAllButton } from '../grid/DeleteAllButton';
import { PortfolioAgreementDisclaimerContentBlocker } from '../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerContentBlocker';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureButton } from '../access/FeatureButton';

export const EditPortfolio = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const referenceName = params.referenceName;

    useEffect(() => {
        if (referenceName) {
            dispatch(actions.init(referenceName))
        } else {
            dispatch(securitiesActions.init([]));
        }

        return () => {
            dispatch(actions.reset())
        }
    }, [dispatch, referenceName])

    const portfolio = useSelector((state) => state.editPortfolio.portfolio);
    const renamePortfolioDialog = useSelector((state) => state.editPortfolio.renamePortfolioDialog);
    const securities = useSelector((state) => state.grid.dataItems);
    const deletePortfolioDialog = useSelector((state) => state.editPortfolio.deletePortfolioDialog);
    const deleted = useSelector((state) => state.editPortfolio.deleted);
    const saved = useSelector((state) => state.editPortfolio.saved);
    const isLoading = useSelector((state) => state.editPortfolio.isLoading);
    const updatePortfolioStatus = useSelector((state) => state.editPortfolio.updatePortfolioRequestState === RequestState.request);
    const isGridUploading = useSelector((state) => state.grid.upload.status === uploadStatus.uploading);
    const isRequestingExportPortfolio = useSelector((state) => state.editPortfolio.isRequestingExportPortfolio);
    const isFlaggedModalVisible = useSelector((state) => state.securities.isFlaggedModalVisible);

    const [isPortfolioNameDialogVisible, setPortfolioNameDialogVisible] = useState(false);
    const [newPortFolioName, setNewPortFolioName] = useState(portfolio.name || '');
    const [updatedPortfolioName, setUpdatedPortfolioName] = useState('');

    const hasSecurities = securities.some(s => !s.draft);
    const hasErrors = securities.some(c => !c.draft && c.errors && c.errors.length);
    const canSave = hasSecurities && !isGridUploading;

    useEffect(() => {
        if (hasErrors) {
            setPortfolioNameDialogVisible(false)
        }
    }, [hasErrors])

    const handleBlockNavigation = () =>
        !securitiesComparer.compareMultiple(portfolio.securities, securities.filter(s => !s.draft));

    const handleOnNewPortfolioName = (name) => {
        setNewPortFolioName(name);
        setPortfolioNameDialogVisible(false);
        dispatch(actions.createNewPortfolio(name))
    }

    const handleSetUpdatedPortfolioName = (name) => {
        setUpdatedPortfolioName(name);
        dispatch(actions.renamePortfolioDialog(false));
    }

    const handleSavePortfolio = () => {
        dispatch(gridActions.validate());
        if (referenceName) {
            dispatch(actions.updatePortfolioSecurities(updatedPortfolioName))
        } else if (newPortFolioName) {
            dispatch(actions.createNewPortfolio(newPortFolioName))
        } else {
            setPortfolioNameDialogVisible(true);
        }
    }

    if (deleted || saved) {
        return null;
    }

    return (
        <PortfolioAgreementDisclaimerContentBlocker onCancel={() => history.replace(routes.portfolio)}>
            <Preloader inProgress={isLoading || updatePortfolioStatus}>
                <div className="portfolio container">
                    <div className="sub-header">
                        <div className="sub-header-row type01 flex-row position-relative">
                            <Breadcrumbs>
                                <BreadcrumbsItem route={routes.portfolio} text='Portfolio' />
                            </Breadcrumbs>
                            <h1>
                                {referenceName
                                    ?
                                    <OnHoverTooltip overlay={updatedPortfolioName || portfolio.name}>
                                        <span className="portfolio-name-title text-ellipsis">{updatedPortfolioName || portfolio.name}</span>
                                    </OnHoverTooltip>
                                    : (
                                        <span className={cn({ "title-placeholder": !hasSecurities })}>
                                            Portfolio Name
                                        </span>
                                    )
                                }
                            </h1>
                            {
                                !!referenceName && (
                                    <div className="content-edit">
                                        <button
                                            className="btn-link"
                                            type="button"
                                            onClick={() => dispatch(actions.renamePortfolioDialog(true))}
                                        >
                                            <IconSVG name="edit-pencil" width={16} height={16} />
                                        </button>
                                    </div>
                                )
                            }
                            <div className="flex-item-right controls">
                                <DeleteFlaggedItemsButton disabled={isGridUploading} />
                                <DeleteRowsWithErrorButton />
                                <DeleteAllButton disabled={isGridUploading} />
                                <DownloadTemplateButton templateType="SecuritiesXlsxTemplate" disabled={isGridUploading} />
                                {
                                    referenceName && (
                                        <Preloader
                                            inProgress={isRequestingExportPortfolio}
                                            fullScreen={false}
                                            small={true}
                                            text="Exporting…"
                                        >
                                            <ActionBlocker feature={SubscriptionFeature.exportPortfolios}>
                                                <FeatureButton
                                                    className="btn-link"
                                                    disabled={!portfolio || !portfolio.securities.length || isGridUploading}
                                                    onClick={() => dispatch(actions.exportPortfolio(portfolio.id))}
                                                >
                                                    <IconSVG name="export" width={16} height={16} />Export
                                                </FeatureButton>
                                            </ActionBlocker>
                                        </Preloader>
                                    )
                                }
                                <button
                                    type="button"
                                    className="btn btn-ghost"
                                    onClick={() => history.push(routes.portfolio)}
                                    disabled={isGridUploading}
                                >
                                    Cancel
                                </button>
                                {
                                    referenceName && (
                                        <button
                                            className="btn btn-ghost btn-danger"
                                            type="button"
                                            onClick={() => dispatch(actions.deletePortfolioDialog(true))}
                                            disabled={!referenceName}
                                        >
                                            DELETE PORTFOLIO
                                        </button>
                                    )
                                }
                                <button
                                    type="button"
                                    className="btn btn-main"
                                    disabled={!canSave || !!hasErrors}
                                    onClick={handleSavePortfolio}
                                >
                                    SAVE
                                </button>
                                {isFlaggedModalVisible && <SecuritiesGridFlaggedPopup
                                    onClose={() => dispatch(securitiesActions.setFlaggedModal(false))} />}
                            </div>
                        </div>
                    </div>
                    <Grid
                        onDataItemsUpload={file => dispatch(securitiesActions.uploadSecurities(file, true))}
                        onDataItemsPaste={clipboardText => dispatch(securitiesActions.handleClipboardData(clipboardText, true))}
                        onFileSelected={(file) => dispatch(securitiesActions.uploadSecurities(file, true))}
                        helpPopoverTitle="You can add your securities in the following ways"
                    />
                    {
                        renamePortfolioDialog.visible && (
                            <PortfolioNameDialog
                                onClose={() => dispatch(actions.renamePortfolioDialog(false))}
                                onNameChange={handleSetUpdatedPortfolioName}
                                title="Edit Portfolio Name"
                                portfolioName={portfolio.name}
                            />
                        )
                    }
                    {
                        deletePortfolioDialog.visible &&
                        <Confirm
                            text={`Please confirm you would like to delete ${portfolio.name}.`}
                            onCancel={() => dispatch(actions.deletePortfolioDialog(false))}
                            onConfirm={() => dispatch(actions.deletePortfolio(portfolio.id))}
                        />
                    }
                    {
                        isPortfolioNameDialogVisible && !hasErrors && (
                            <PortfolioNameDialog
                                onClose={() => setPortfolioNameDialogVisible(false)}
                                onNameChange={handleOnNewPortfolioName}
                                title="Save New Portfolio"
                            />
                        )
                    }
                    <RouteLeavingGuard
                        navigate={pathname => history.push(pathname)}
                        shouldBlockNavigation={handleBlockNavigation}
                    />
                </div>
            </Preloader>
        </PortfolioAgreementDisclaimerContentBlocker>
    );
}
