import React from 'react';
import classNames from "classnames";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { processActions } from "../../actions";
import { useAppSelector } from "../../effects/useAppSelector";
import { dateTimeUtils } from "../../utils";
import { FormError } from "../controls";
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { useAppDispatch } from '../../effects/useAppDispatch';

export function CutOffReminder({ disabled }: { disabled?: boolean }) {
    const dispatch = useAppDispatch();

    const error = useAppSelector(s => s.process.errors.templates.cutOffReminder);
    const cutOffReminderTimeSpan = useAppSelector(s => s.process.templates.current?.cutOffReminder);
    const cutOffReminder = cutOffReminderTimeSpan
        ? dateTimeUtils.parseTimeSpan(cutOffReminderTimeSpan).totalMinutes
        : undefined;
    const disabledCutOffReminderTooltipText = "Not applicable for current process settings. This option is only available if 'End Stage 1' is set as 'Automatically'"

    return (
        <OnHoverTooltip overlay={disabled && disabledCutOffReminderTooltipText}>
            <div className="form-control-wrapper">
                <MaskedInput
                    value={String(cutOffReminder || "")}
                    className={classNames(
                        'form-control range',
                        { 'is-invalid': Boolean(error) }
                    )}
                    maxLength={3}
                    mask={createNumberMask({
                        prefix: "",
                        suffix: "",
                        allowDecimal: false,
                        includeThousandsSeparator: false,
                        integerLimit: 3,
                    })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(processActions.cutOffReminderChange(e.target.value))}
                    disabled={disabled}
                />
                <span className="info-label">
                    Minutes
                </span >
                <FormError message={error} />
            </div>
        </OnHoverTooltip>
    );
}
