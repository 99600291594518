import cn from 'classnames';
import { SecurityBwicStatistics } from '../../../types/security/SecurityBwicStatistics';
import { formatUtils } from '../../../utils/format.utils';


interface Props {
    bwicHistory: SecurityBwicStatistics[];
}

export const PortfolioLastTraded = ({ bwicHistory }: Props) => {
    const color = bwicHistory?.[0]?.color;

    if (!color) {
        return null;
    }

    const [tradedText] = formatUtils.formatColor(color).split(' ');
    const [, levelSpecification, level] = formatUtils.formatColor(color).split(' ');

    return (
        <span className={`bwic-color-traded-info status active ${cn({ 'blocked': !color.traded })}`}>
            {tradedText || 'Traded'} {levelSpecification} {level}
        </span>
    );
};
