import React from 'react';
import MaskedInput from "react-text-mask";
import classNames from "classnames";
import { useAppSelector } from "../../effects/useAppSelector";
import { FormError } from "../controls/FormError";
import { RadioButton } from '../controls/RadioButton';
import { createNumberMask } from "text-mask-addons";
import { processActions } from "../../actions/process.actions";
import { formatUtils } from '../../utils/format.utils';
import { numericUtils } from '../../utils';
import { constants } from '../../constants/constants';
import { useAppDispatch } from '../../effects/useAppDispatch';

export function MinBidIncrement() {
    const dispatch = useAppDispatch();

    const minBidIncrement = useAppSelector(s => s.process.templates.current?.minBidIncrement);
    const error = useAppSelector(s => s.process.errors.templates.minBidIncrement);

    return (
        <>
            <div className="radio-group">
                <RadioButton
                    label="Off"
                    name="minimum-bid-improvement"
                    checked={minBidIncrement == null}
                    onChange={() => dispatch(processActions.minBidIncrementDisabled())}
                />
                <RadioButton
                    label="On"
                    name="minimum-bid-improvement"
                    checked={minBidIncrement != null}
                    onChange={() => dispatch(processActions.minBidIncrementEnabled())}
                />
                <label className="form-label"><span className="text-red">*</span></label>
            </div>
            <div className="form-control-wrapper">
                {
                    minBidIncrement == null
                        ? <input
                            disabled
                            className="form-control range"
                            placeholder={formatUtils.formatBid(constants.defaultMinBidIncrement)}
                        />
                        : <MaskedInput
                            value={minBidIncrement || ''}
                            className={classNames(
                                'form-control range',
                                { 'is-invalid': minBidIncrement != null && error }
                            )}
                            mask={createNumberMask({
                                prefix: "",
                                suffix: "",
                                allowDecimal: true,
                                includeThousandsSeparator: false,
                                integerLimit: 2,
                                decimalLimit: 4,
                            })}
                            onChange={e => dispatch(processActions.minBidIncrementChange(e.target.value))}
                            onBlur={() =>
                                numericUtils.isNumber(minBidIncrement) &&
                                dispatch(processActions.minBidIncrementChange(formatUtils.formatBid(minBidIncrement)))
                            }
                        />
                }
                <span className="info-label">
                    Bps
                </span >
                <FormError message={minBidIncrement != null && error} />
            </div>
        </>
    );
}
