import { processActions as actions } from '../../actions/process.actions';
import { classnames } from '../../styles/classnames';
import { DayPickerInput } from '../controls/DayPickerInput';
import { useAppSelector } from '../../effects/useAppSelector';
import { useAppDispatch } from '../../effects/useAppDispatch';

export const BwicDate = () => {
    const dispatch = useAppDispatch();
    const handleDayChange = (day?: Date | string) => dispatch(actions.changeDate(day));
    const minimumDate = useAppSelector(s => s.process.minimumDate);
    const date = useAppSelector(s => s.process.date);
    const holidays = useAppSelector(s => s.process.holidays || []);
    const error = useAppSelector(s => s.process.errors && s.process.errors.date);

    const css = classnames({
        'form-control form-control-sm': true,
        'is-invalid': !!error
    });

    return (
        <>
            <label className="form-label">Date</label>
            <DayPickerInput
                onDayChange={handleDayChange}
                minimumDate={minimumDate}
                selectedDate={date}
                allowWeekends={false}
                disabledDays={holidays.map(h => new Date(h))}
                className={css}
            />
            {!!error && <div className="form-error">{error}</div>}
        </>
    );
}
