import moment from "moment";
import cn from 'classnames';
import { constants } from "../../../constants";
import { useAppSelector } from "../../../effects/useAppSelector";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { PortfolioSecurity } from "../../../types/portfolio/PortfolioSecurity";
import { amrFormatUtils, dateTimeUtils } from "../../../utils";
import { isRequesting } from "../../../utils/request-state.utils";
import { ValueBlocker } from "../../access/ValueBlocker";
import { Preloader } from "../../common/Preloader";

interface Props {
    security: PortfolioSecurity
}

export function PortfolioRollerDeadline({ security }: Props) {
    const requestState = useAppSelector(s => s.entities.latestTransaction.requestState);
    const transaction = useAppSelector(s => security.dealTicker
        ? s.entities.latestTransaction.items[security.dealTicker.toLowerCase()]
        : undefined
    )
    const requiredFeatures = [
        SubscriptionFeature.IssuanceMonitorArrangerPipeline,
        SubscriptionFeature.CanLookupTransactionFromPortfolio
    ];

    const rollerDeadline = transaction?.rollerDeadline;

    return (
        <Preloader small inProgress={isRequesting(requestState)}>
            <ValueBlocker features={requiredFeatures} requireAllFeatures>
                {
                    rollerDeadline
                        ? (<div className={cn({ today: dateTimeUtils.isToday(rollerDeadline) })}>
                            {amrFormatUtils.formatRollerDeadlineDate(moment(rollerDeadline))}
                        </div>) : constants.emptyPlaceholder
                }
            </ValueBlocker>
        </Preloader>
    );
}