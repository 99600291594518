import React from 'react'
import { SettingsLine } from '../SettingsLine';
import { AutoFeedbackDelay } from '../AutoFeedbackDelay';
import { MinBidIncrementSettingsLine } from '../MinBidIncrementSettingsLine';

export function StandartBiddingSection() {

    return (
        <div className="stages">
            <SettingsLine
                title="Autofeedback delay"
                tooltip="The seller can set a delay in sending auto-feedbacks for all bidders. Delay information will not be disclosed to bidders"
            >
                <AutoFeedbackDelay />
            </SettingsLine>
            <MinBidIncrementSettingsLine />
        </div>
    );
}

