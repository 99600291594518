import React, { useMemo, useRef } from 'react';
import { constants } from '../../../constants';
import InfiniteScroll from 'react-infinite-scroller';
import { Notification } from '../../../types/notifications/Notification';
import { arrayUtils } from '../../../utils/array.utils';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { InfiniteScrollLoader } from '../../common';
import { SlideDownPanel } from '../../common/SlideDownPanel';
import { NotificationDetails } from './Notification';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { isRequesting, isRequestSuccess } from '../../../utils';
import { notificationActions } from '../../../actions/notification.actions';
import { notificationUtils } from '../../../utils/notifications.utils';

interface Props {
    notifications: Notification[];
    visible: boolean;
    onHide: (e: MouseEvent) => void;
}

export const NotificationList: React.FC<Props> = ({ notifications, visible, onHide }) => {
    const dispatch = useDispatch();
    const parentDivRef = useRef<HTMLDivElement | null>(null);

    const connectionStatus = useSelector((state: AppState) => state.notification.connectionStatus);
    const isLoading = useSelector((state: AppState) => isRequesting(state.notification.requestStateGetNotificationList));
    const hasMoreNotifications = useSelector((state: AppState) => state.notification.hasMoreNotifications);
    const countUnreadNotifications = useSelector((state: AppState) => state.notification.countUnreadNotifications);
    const requestStateGetNotificationList = useSelector((state: AppState) => state.notification.requestStateGetNotificationList);

    const sortedByDate = useMemo(() => arrayUtils.groupBy(
        notifications,
        (n: Notification) => dateTimeUtils.utcToLocalString(n.sentDateUtc, constants.dateFormatISO8601)
        )
        , [notifications]
    );
    const isSomeUnreadNotificationInStore = useMemo(() => notifications.some(n => !n.isReadByUser), [notifications]);

    const handleLoadMore = () => {
        if (!isLoading && hasMoreNotifications && notificationUtils.isConnected(connectionStatus)) {
            dispatch(notificationActions.notificationGetList());
        }
    };

    const handleSetRead = (referenceName: string) => {
        dispatch(notificationActions.notificationSetReadRequest(referenceName));
    };

    const handleReadAll = () => {
        dispatch(notificationActions.notificationsReadAllRequest());
    };

    const renderNotifications = (notifications: Notification[]) => notifications.map(n =>
        <NotificationDetails key={n.referenceName} notification={n} onRead={handleSetRead} />
    );

    const renderList = () => {
        const result: React.ReactNode[] = [];
        sortedByDate.forEach((value, key) => {
            result.push(
                <div className="notifications-block" key={key}>
                    <h6 className="text-warm-grey">{dateTimeUtils.durationDays(key)}</h6>
                    {renderNotifications(value)}
                </div>
            )
        });
        return result;
    };

    return (
        <SlideDownPanel className="notification-panel" onHide={onHide} visible={visible}>
            <div className="flex-row title">
                <h3>Notifications</h3>
                {!!notifications.length &&
                    <button
                        className="flex-item-right btn-link"
                        disabled={!(!!countUnreadNotifications || isSomeUnreadNotificationInStore)}
                        onClick={handleReadAll}
                    >
                        Mark all as read
                    </button>
                }
            </div>
            <div className="notification-cnt" ref={parentDivRef}>
                {!notifications.length && !isLoading && isRequestSuccess(requestStateGetNotificationList) && <p className="placeholder">There are no notifications yet.</p>}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMore}
                    hasMore={hasMoreNotifications && !!notifications.length}
                    useWindow={false}
                    getScrollParent={() => parentDivRef.current}
                >
                    {renderList()}
                </InfiniteScroll>
                <InfiniteScrollLoader isNextPageRequesting={isLoading || !notificationUtils.isConnected(connectionStatus)} />
            </div>
        </SlideDownPanel>
    )
};
