import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { constants, PushNotificationServiceStatus } from '../../constants';
import IconSVG from '../../styles/svg-icons';

export function ConnectionStatusToast({ disabled }) {
    const { connectionStatus } = useSelector(state => state.notification);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (connectionStatus === PushNotificationServiceStatus.reconnected) {
            setTimeout(() => {
                if (connectionStatus && connectionStatus === PushNotificationServiceStatus.reconnected) {
                    setVisible(false);
                }
            }, constants.notificationDisplayTimeout);
        } else {
            setVisible(true);
        }
    }, [connectionStatus]);

    if (!visible || disabled) {
        return null;
    }

    const refreshPage = () => window.location.reload();

    if (connectionStatus === PushNotificationServiceStatus.reconnecting) {
        return (
            <div className="connection-toast connection-toast-connecting">
                <div className="connection-toast-icon">
                    <IconSVG name="warning" width={16} height={16} />
                </div>
                <div className="connection-toast-message">Looks like there is a problem with the connection. Connecting...</div>
            </div>
        )
    }

    if (connectionStatus === PushNotificationServiceStatus.reconnected) {
        return (
            <div className="connection-toast connection-toast-connected">
                <div className="connection-toast-message">Connected!</div>
            </div>
        )
    }

    if (connectionStatus === PushNotificationServiceStatus.disconnected) {
        return (
            <div className="connection-toast connection-toast-disconnected">
                <div className="connection-toast-icon">
                    <IconSVG name="warning" width={16} height={16} />
                </div>
                <div className="connection-toast-message">
                    Looks like we're having trouble retrieving some information.
                    Please <span onClick={refreshPage}>refresh the page</span>.
                </div>
            </div>
        )
    }

    return null;
}
