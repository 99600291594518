import React from 'react'
import classNames from 'classnames';
import { FormError } from '../../controls';
import { processActions } from '../../../actions';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useAppSelector } from '../../../effects/useAppSelector';
import { dateTimeUtils } from '../../../utils';
import { constants } from '../../../constants';
import { CutOffReminder } from '../CutOffReminder';
import { SettingsLine } from '../SettingsLine';
import { CutoffReminderSettingsLine } from '../CutoffReminderSettingsLine';
import { MinBidIncrementSettingsLine } from '../MinBidIncrementSettingsLine';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export function BestFootForwardSection() {
    return (
        <div className="stages">
            <MinBidIncrementSettingsLine />
            <h2>Best Foot Forward — One stage only. Bidder can improve bids during the bidding period.</h2>
            <SettingsLine
                title={<>Bids submission duration <span className="text-red">*</span></>}
                tooltip="Bidding period from the start of BWIC"
            >
                <Stage1Deadline />
            </SettingsLine>

            <CutoffReminderSettingsLine>
                <CutOffReminder />
            </CutoffReminderSettingsLine>
        </div>
    );
}

function Stage1Deadline() {
    const dispatch = useAppDispatch();

    const stageDeadlineTimeSpan = useAppSelector(s => s.process.templates.current?.bff?.stage1Deadline);

    const stageDeadline = stageDeadlineTimeSpan
        ? dateTimeUtils.parseTimeSpan(stageDeadlineTimeSpan).totalMinutes
        : undefined;
    const error = useAppSelector(s => s.process.errors.templates.bestFootForwardDurationMinutes)

    return (
        <>
            <div className="form-control-wrapper">
                <MaskedInput
                    value={String(stageDeadline || "")}
                    disabled={stageDeadlineTimeSpan == null}
                    placeholder={
                        stageDeadlineTimeSpan == null
                            ? String(constants.bestFootForward.stage1DeadlineDefaultMinutes)
                            : undefined
                    }
                    className={classNames(
                        'form-control range',
                        { 'is-invalid': stageDeadlineTimeSpan != null && error }
                    )}
                    maxLength={3}
                    mask={createNumberMask({
                        prefix: "",
                        suffix: "",
                        allowDecimal: false,
                        includeThousandsSeparator: false,
                        integerLimit: 3,
                    })}
                    onChange={e => dispatch(processActions.bestFootForwardDurationMinutesChange(e.target.value))}
                />
                <span className="info-label">
                    Minutes
                </span >
                <FormError message={stageDeadlineTimeSpan != null && error} />
            </div>
        </>
    );
}

