import React, { Component } from 'react';
import { connect } from 'react-redux';
import { processActions as actions } from '../../actions';


class TimeZone extends Component {
    render = () => {
        const { selectedTimeZone, timeZones } = this.props;
        const options = timeZones && timeZones.map(tz => <option key={tz.id} value={tz.id}>{tz.title}</option>)

        return (
            <>
                <label className="form-label">Time Zone</label>
                <select className="form-control form-control-sm form-select" value={selectedTimeZone} onChange={this.handleTimeZoneChanged}>
                    {options}
                </select>
            </>
        );
    }

    handleTimeZoneChanged = e => this.props.dispatch(actions.changeTimeZone(e.target.value));
}

const mapStateToProps = ({ process }) => ({
    selectedTimeZone: process.timeZone,
    timeZones: process.timeZones
});


const connectedTimeZone = connect(mapStateToProps)(TimeZone);
export { connectedTimeZone as TimeZone };
