import cn from 'classnames';
import { useBrowserTabBlinkAnimation } from "../../effects/useBrowserTabBlinkAnimation";
import { ModalNotification as IModalNotification } from "../../types/notifications/ModalNotification";
import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { Popup, PopupBody, PopupFooter } from "../controls";
import { StatusMessageSection } from "../status-message/StatusMessageSection";
import { NotificationBody } from "./NotificationBody";

interface Props {
    notification: IModalNotification;
    onClose: () => void
}

export function ModalNotification({ notification, onClose }: Props) {
    const { title, message, refreshButtonVisible, type, blinkBrowserTabText } = notification;

    useBrowserTabBlinkAnimation(blinkBrowserTabText ?? []);

    return (
        <Popup
            modalClass={cn("modal-notifications", notification.popupClassName)}
            title={<NotificationBody text={title} className="flex-row" />}
            onClose={onClose}
        >
            <PopupBody>
                <StatusMessageSection type={type ?? StatusMessageSectionType.Error}>
                    <NotificationBody text={message} />
                </StatusMessageSection>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Close
                </button>
                {
                    refreshButtonVisible &&
                    <button className="btn btn-main" onClick={() => document.location.reload()}>
                        Refresh Page
                    </button>
                }
            </PopupFooter>
        </Popup>
    );
}