import classNames from 'classnames';
import { processActions as actions } from '../../actions';
import { useAppSelector } from '../../effects/useAppSelector';
import IconSVG from '../../styles/svg-icons';
import { BwicProcessType, NewBwicProcess } from '../../types/models/Process';
import { OnHoverTooltip } from '../common';
import { ProcessDescription } from './processDescription/ProcessDescription';
import { useAppDispatch } from '../../effects/useAppDispatch';

export function TemplateList() {
    const dispatch = useAppDispatch();
    const templates: NewBwicProcess[] = useAppSelector(s => s.process.templates?.all) ?? [];
    const selectedTemplate = useAppSelector(s => s.process.templates.current);

    if (!templates.length) {
        return null;
    }

    const templateItems = templates.map((t, index) => {
        const isSelected = selectedTemplate && t.id === selectedTemplate.id;

        return (
            <li key={index}
                className={classNames('template-list-item', { selected: isSelected })}
                onClick={() => dispatch(actions.selectTemplate(index))
                }
            >
                <div className="template-list-item-title">
                    {t.title}
                    {t.type === BwicProcessType.Live &&
                        <OnHoverTooltip overlay="In Stage 1, participants submit initial bids. In Stage 2 a specified number
                                of top bidders from Stage 1 can see the live Best Bid level and can
                                improve their bids during the pre-set interval of time. Bidding continues till
                                either improvement received during the improvement interval of BWIC ends."
                        >
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    }
                    {isSelected && <IconSVG name="affirm" width={16} height={16} />}
                </div>
                <ProcessDescription isSelected={isSelected} descriptionData={isSelected ? selectedTemplate : t }  />
            </li>
        );
    });

    return (
        <div className="sidebar flex-item-sidebar container-flex">
            <div className="flex-item-sidebar-title">
                <div className="flex-row">
                    <h2>Templates</h2>
                </div>
                <div className="status-message info">
                    <i className="icon icon-info"></i>
                    <span className="status-message-cnt">
                        Highlighted parameters are customizable by the Seller
                    </span>
                </div>
            </div>
            <div className="flex-item-sidebar-cnt container-flex">
                <ul className="template-list">{templateItems}</ul>
            </div>
        </div>
    );
}
