import IconSVG from "../../styles/svg-icons";
import { FlexRow, OnHoverTooltip } from "../common";

interface LineProps {
    title: React.ReactNode;
    tooltip?: React.ReactNode;
    children: React.ReactNode;
}

export function SettingsLine({ title, tooltip, children }: LineProps) {
    return (
        <div className="section-row flex-row">
            <div className="section-row-title">
                <label className="form-label">
                    {title}
                    {tooltip && <OnHoverTooltip overlay={tooltip}>
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>}
                </label>
            </div>
            <div className="section-row-cnt">
                <FlexRow>
                    {children}
                </FlexRow>
            </div>
        </div>
    )
}