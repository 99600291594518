import React from 'react';
import { Notification } from '../../../types/notifications/Notification';
import classNames from 'classnames';
import dateTimeUtils from '../../../utils/dateTime.utils';
import { constants } from '../../../constants';

interface Props {
    notification: Notification,
    onRead: (referenceName: string) => void;
}

export const NotificationDetails: React.FC<Props> = ({ notification, onRead }) => {
    const renderMessage = (message: string) => {
        const messageLines = String(message).trim().split('\n');
        return messageLines.map((message, index) => <span key={index}>{message}<br/></span>);
    };

    return (
        <div
            className={classNames('box flex-row align-items-flex-start', { 'new': !notification.isReadByUser })}
            key={notification.referenceName}
            onMouseEnter={() => !notification.isReadByUser && onRead(notification.referenceName)}
        >
            {!notification.isReadByUser && 
                <span className="alert-badge flex-none" />
            }
            <div className="flex-row-full-size">
                <div className="flex-row">
                    <div className="text-left subject">{notification.subject}</div>
                    <div className={classNames('flex-item-right time', { 'text-red': !notification.isReadByUser })}>
                        {dateTimeUtils.utcToLocalString(notification.sentDateUtc, constants.timeFormat)}
                    </div>
                </div>
                <p className="text-warm-grey text-left">{renderMessage(notification.message)}</p>
            </div>
        </div>
    )
};
